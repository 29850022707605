import { Injectable, Optional, SkipSelf } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})

// https://www.geekstrick.com/easily-translate-angular-12-app-using-ngx-translate/
export class LocalizationService {
  private _localeId = 'en';

  constructor(
    @Optional() @SkipSelf() private singleton: LocalizationService,
    private translateService: TranslateService,
  ) {
    if (this.singleton) throw new Error();
    this._localeId = this.getLocaleId();
  }

  public initService(): Promise<void> {
    // language code same as file name.
    this._localeId = this.getLocaleId();
    return this.useLanguage(this._localeId);
  }

  public useLanguage(lang: string): Promise<void> {
    this.translateService.setDefaultLang(lang);
    this._localeId = lang;
    return this.translateService
      .use(lang)
      .toPromise()
      .catch((e) => {
        throw new Error('LocalizationService.init failed ' + e);
      });
  }

  public translate(key: string | string[], interpolateParams?: object): string {
    return this.translateService.instant(key, interpolateParams) as string;
  }

  public getLocaleId(): string {
    return localStorage.getItem('language') || 'en';
  }
}
