import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { StateManagerService } from '../../services/state-manager.service';
import { DownloadPDFResponse, scrollToTop, downloadDocument, CommonLibService } from 'projects/common-lib/src/public-api';
import { ComplaintService } from '../../services/complaint.service';

@Component({
  selector: 'app-end-page',
  templateUrl: './end-page.component.html',
  styleUrls: ['./end-page.component.scss'],
})
export class EndPageComponent implements OnInit {

  referenceId = '';
  showLoadingIndicator = false;
  showSuccess = true;
  downloadError = false;
  sessionUUID: string;

  constructor(
    private titleService: Title,
    private router: Router,
    private stateManagerService: StateManagerService,
    private commonLibService: CommonLibService,
    private complaintService: ComplaintService,
  ) {
    if (this.router.url.includes('fr/tssea')) {
      this.titleService.setTitle("Envoyé avec succès | BSREV"); // French tab title
    }
    else {
      this.titleService.setTitle('Successfully submitted | TVSO'); // English tab title
    }

    this.sessionUUID = stateManagerService.getSessionUUID();
  }

  ngOnInit() {
    this.referenceId = this.stateManagerService.getMtoReferenceId();
  }

  downloadComplaint() {

    // spinner
    this.showLoadingIndicator = true;

    this.complaintService.downloadPDF(
      this.sessionUUID,
      this.stateManagerService.getMtoReferenceId(),
      this.commonLibService.getCurrentLanguage() === 'fr' ? 'FR' : 'EN',
      new Date().toLocaleDateString()
    ).subscribe({
      next: (result: DownloadPDFResponse) => {

        scrollToTop();

        this.showSuccess = true;
        this.downloadError = false;
        this.showLoadingIndicator = false;

        // download document
        downloadDocument(result.pdfByteArray);

      },
      error: (error: DownloadPDFResponse) => {
        // show error
        scrollToTop();

        this.showSuccess = false;
        this.downloadError = true;
        this.showLoadingIndicator = false;

        console.error(error.message);
      },
    });
  }
}
