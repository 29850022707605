import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { PublicRouteEnum } from 'projects/common-lib/src/public-api';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { StateManagerService } from './state-manager.service';


@Injectable({
  providedIn: 'root'
})
export class RouteGuardService implements CanActivate {

  constructor(private stateManagerService: StateManagerService, private router: Router,) { }

  canActivate(route: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    if (this.stateManagerService.getRouteStep() === PublicRouteEnum.SUBMIT_COMPLAINT && route.data['expectedStep'] === PublicRouteEnum.ENTER_INCIDENT_INFO) {
      // if the browser back button is pressed on the last page, clear the state object
      this.stateManagerService.clearStateObject();
    }

    // only do this route guard if enabled in environment file
    if (!environment.disableRouteGuard) {

      if (this.stateManagerService.getRouteStep() != route.data['expectedStep']) {
        // block user from going forward

        // get language from url
        const isFrench = location.href.includes('fr');

        // route back to landing page
        this.stateManagerService.setStepperStep(1);
        this.stateManagerService.setRouteStep(PublicRouteEnum.LANDING_PAGE)

        if (isFrench) {
          return this.router.navigate(['fr', `${environment.rootRoute}`]);
        } else {
          return this.router.navigate([`${environment.rootRoute}`]);
        }
      }
      // otherwise allow them to go next
      return true;
    }

    return true;
  }
}
