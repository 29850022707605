import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  @Input() isAdmin = false;

  isFrench = location.href.includes('fr/tssea');

  linkMap = {
    accessability_en: "https://www.ontario.ca/page/accessibility",
    accessability_fr: "https://www.ontario.ca/fr/page/accessibilite",
    privacy_en: "https://www.ontario.ca/page/privacy-statement",
    privacy_fr: "https://www.ontario.ca/fr/page/declaration-concernant-la-protection-de-la-vie-privee",
    contact_en: "https://www.ontario.ca/page/get-towing-vehicle-storage-certificate#section-7",
    contact_fr: "https://www.ontario.ca/fr/page/obtenez-certificat-remorquage-dentreposage-vehicules#section-7",
    contact_admin: "https://www.ontario.ca/page/government-ontario",
    copyright_en: "https://www.ontario.ca/page/copyright-information",
    copyright_fr: "https://www.ontario.ca/fr/page/droits-dauteur",
  }

  constructor() { }

  routeToExternalMTOLink(link: string) {

    let extRoute = '';
    this.isFrench = location.href.includes('fr/tssea');

    switch (link) {
      case 'accessability':
        this.isFrench ? extRoute = this.linkMap.accessability_fr : extRoute = this.linkMap.accessability_en;
        break;
      case 'privacy':
        this.isFrench ? extRoute = this.linkMap.privacy_fr : extRoute = this.linkMap.privacy_en;
        break;
      case 'contact':
        this.isAdmin ? extRoute = this.linkMap.contact_admin : this.isFrench ? extRoute = this.linkMap.contact_fr : extRoute = this.linkMap.contact_en;
        break;
      case 'copyright':
        this.isFrench ? extRoute = this.linkMap.copyright_fr : extRoute = this.linkMap.copyright_en;
        break;
    }

    if (extRoute != '') {
      window.open(extRoute, "_blank");
    }

  }

}
