import { Injectable } from '@angular/core';
import { TsoaComplaint, PartnerDomainModel, PublicRouteEnum, ContactInfo, IncidentSummary, TowTruckInformation, TowDriverInformation, VehicleStorageInformation, ComplainAbout, IncidentInformation } from 'projects/common-lib/src/public-api';
import partnerDomainModelJSON from 'projects/common-lib/src/lib/assets/jsonData/partnerDomainModel.json';

@Injectable({
  providedIn: 'root',
})
export class StateManagerService {
  private emptyStateObject: TsoaComplaint;
  private stateObject: TsoaComplaint;
  private partnerDomainsMapping: PartnerDomainModel[];
  private routeStep: PublicRouteEnum = PublicRouteEnum.LANDING_PAGE;

  stepperStep = 1;
  showStorageInfo: boolean | null = null;
  showVehicleTowingOrTowDriverInfo: boolean | null = null;

  constructor() {
    this.emptyStateObject = {
      mtoReferenceId: '',
      contactInfo: {
        fullName: '',
        phoneNumber: '',
        emailAddress: '',
        organizationType: '',
        organizationName: '',
      },
      incidentInformation: {
        complainAbout: {
          complaintSubject: [],
          typeOfInteraction: '',
          typeOfComplaint: [],
        },
        information: {
          vehicles: [],
          exactDateTime: '',
          locationOfIncident: '',
        },
        serviceProvider: {
          towTruckInformation: {
            towCertificateNum: '',
            cvorNum: '',
            towingCompanyName: '',
            nameOnTruck: '',
            plateNum: '',
          },
          towDriverInformation: {
            towDriverCertificateNum: '',
            towDriverLicenceNum: '',
            towDriverName: '',
          },
          vehicleStorageInformation: {
            storageLocationNum: '',
            addressOfStoredVehicle: '',
            storageCertificateNum: '',
            vehicleStorageCompanyName: '',
          },
        },
        summary: {
          clientReferenceNum: '',
          summaryOfIncident: '',
          documents: []
        },
      },
      complaintPriority: '',
      complaintStatus: '',
      mtoComments: [],
      mtoDecision: '',
      towOpSentToRlso: false,
      towDrvSentToRlso: false,
      vehStorageSentToRlso: false,
      sessionUUID: ''
    };

    this.stateObject = this.deepCopy(this.emptyStateObject);

    this.partnerDomainsMapping = partnerDomainModelJSON.mapData;
  }

  getSessionUUID(): Readonly<string> {
    return this.stateObject.sessionUUID;
  }

  setSessionUUID(incoming: string) {
    this.stateObject.sessionUUID = incoming;
  }

  getShowStorageInfo(): Readonly<boolean | null> {
    return this.showStorageInfo;
  }

  setShowStorageInfo(incoming: boolean) {
    this.showStorageInfo = incoming;
  }

  getShowVehicleTowingOrTowDriverInfo(): Readonly<boolean | null> {
    return this.showVehicleTowingOrTowDriverInfo;
  }

  setShowVehicleTowingOrTowDriverInfo(incoming: boolean) {
    this.showVehicleTowingOrTowDriverInfo = incoming;
  }

  getStepperStep(): Readonly<number> {
    return this.stepperStep < 1 ? 1 : this.stepperStep;
  }

  setStepperStep(incoming: number) {
    this.stepperStep = incoming;
  }

  getRouteStep() {
    return this.routeStep;
  }

  setRouteStep(incoming: number) {
    this.routeStep = incoming;
  }

  deepCopy(obj: TsoaComplaint): TsoaComplaint {
    return JSON.parse(JSON.stringify(obj));
  }

  clearStateObject() {
    this.setStepperStep(1);
    this.stateObject = this.deepCopy(this.emptyStateObject);
  }

  getStateObject(): Readonly<TsoaComplaint> {
    // Map to array of UUIDs and then update all of incident summary and then send 
    const ret = this.stateObject;

    const mapped = ret.incidentInformation.summary.documents.map(file => file.UUID);
    ret.incidentInformation.summary.documents = mapped;

    return this.stateObject;
  }

  getContactInfo(): Readonly<ContactInfo> {
    return { ...this.stateObject.contactInfo };
  }

  updateContactInfo(incoming: Partial<ContactInfo>) {
    this.stateObject.contactInfo = { ...this.stateObject.contactInfo, ...incoming };
  }

  getSummary(): Readonly<IncidentSummary> {
    return { ...this.stateObject.incidentInformation.summary };
  }

  updateSummary(incoming: Partial<IncidentSummary>) {
    this.stateObject.incidentInformation.summary = { ...this.stateObject.incidentInformation.summary, ...incoming };
  }

  getTowTruckInformation(): Readonly<TowTruckInformation> {
    return { ...this.stateObject.incidentInformation.serviceProvider.towTruckInformation };
  }

  updateTowTruckInformation(incoming: Partial<TowTruckInformation>) {
    this.stateObject.incidentInformation.serviceProvider.towTruckInformation = {
      ...this.stateObject.incidentInformation.serviceProvider.towTruckInformation,
      ...incoming,
    };
  }

  getTowDriverInformation(): Readonly<TowDriverInformation> {
    return { ...this.stateObject.incidentInformation.serviceProvider.towDriverInformation };
  }

  updateTowDriverInformation(incoming: Partial<TowDriverInformation>) {
    this.stateObject.incidentInformation.serviceProvider.towDriverInformation = {
      ...this.stateObject.incidentInformation.serviceProvider.towDriverInformation,
      ...incoming,
    };
  }

  getVehicleStorageInformation(): Readonly<VehicleStorageInformation> {
    return { ...this.stateObject.incidentInformation.serviceProvider.vehicleStorageInformation };
  }

  updateVehicleStorageInformation(incoming: Partial<VehicleStorageInformation>) {
    this.stateObject.incidentInformation.serviceProvider.vehicleStorageInformation = {
      ...this.stateObject.incidentInformation.serviceProvider.vehicleStorageInformation,
      ...incoming,
    };
  }

  getComplainAbout(): Readonly<ComplainAbout> {
    return { ...this.stateObject.incidentInformation.complainAbout };
  }

  updateComplainAbout(incoming: Partial<ComplainAbout>) {
    this.stateObject.incidentInformation.complainAbout = {
      ...this.stateObject.incidentInformation.complainAbout,
      ...incoming,
    };
  }

  getInformation(): Readonly<IncidentInformation> {
    return { ...this.stateObject.incidentInformation.information };
  }

  updateInformation(incoming: Partial<IncidentInformation>) {
    this.stateObject.incidentInformation.information = {
      ...this.stateObject.incidentInformation.information,
      ...incoming,
    };
  }

  updateMtoReferenceId(incoming: string) {
    this.stateObject.mtoReferenceId = incoming;
  }

  getMtoReferenceId(): Readonly<string> {
    return this.stateObject.mtoReferenceId;
  }

  getPartnerDomainsMapping(): PartnerDomainModel[] {
    return this.partnerDomainsMapping;
  }

  getPartnerOrganizationsFromPartnerDomainJSON(): string[] {
    return this.getPartnerDomainsMapping()
      .map((obj) => {
        return obj.organization;
      })
      .filter((org, index, arr) => {
        return arr.indexOf(org) === index;
      });
  }

  getPartnerOrganizationFromDomain(domain: string): string {
    const org = this.getPartnerDomainsMapping().find((partner) => {
      return partner.emailDomain.toLowerCase() === domain.toLowerCase();
    });
    return org !== undefined ? org.organization : 'NOT_APPLICABLE';
  }
}
