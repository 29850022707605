import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lib-file-download-widget',
  templateUrl: './file-download-widget.component.html',
  styleUrls: ['./file-download-widget.component.scss']
})
export class FileDownloadWidgetComponent {
  @Input() file!: string;
  @Input() status!: boolean;
  @Output() thisOneDad = new EventEmitter<string>()

  displayName!: string;

  ngOnInit() {

    // first remove the first 37 digits since that is the doc uuid
    if (this.file.length > 37) {
      let filename = this.file.substring(37);
      // if the name is still longer than 30 chars, do the shortening thing
      if (filename.length > 30) {
        const split = this.file.split('.');

        const extension = split.pop();
        filename = filename.substring(0, 25);

        this.displayName = filename + "... ." + extension;
      }
      else {
        this.displayName = filename;
      }
    }
  }

  downloadAttachment() {
    if (this.status === false) {
      // we don't want to allow to download this file
      return;
    }

    this.thisOneDad.emit(this.file);
  }
}
