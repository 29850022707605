import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalizationService } from './localization.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'any',
})
export class LocalizationGuard implements CanActivate {
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private translate: TranslateService,
    private localizationService: LocalizationService,
  ) { }

  canActivate(route: any): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // check if route contains language identification
    if (route.url[0] !== undefined) {
      // check with available languages
      if (route.url[0].path == 'fr') {
        this.translate.use('fr');
        this.localizationService.useLanguage('fr');
        this.locale = 'fr';
      }
      else {
        // switch to english as default
        this.translate.use('en');
        this.locale = 'en-CA';
        this.localizationService.useLanguage('en');
      }
    }

    return true;
  }
}
