<div>
    <div class='ontario-row' style="padding-bottom: 2rem;">
        <div class='ontario-columns'>
            <h2 class="ontario-h1">{{'tsoa-cfs-public.firstFormsPage.title' | translate}}</h2>
        </div>
    </div>
    <form [formGroup]="externalForm">
        <form [formGroup]="inputForm" style="padding-bottom: 2rem;">
            <div class="ontario-row">
                <div class='ontario-columns ontario-small-12 ontario-medium-12 ontario-large-12'>
                    <div>
                        <h2 style="margin: 0;"> {{ 'tsoa-cfs-public.firstFormsPage.Who are you submitting this complaint
                            about?' | translate}} </h2>
                        <p style="margin-top: 1.25rem;">{{'tsoa-cfs-public.firstFormsPage.Select all that apply' |
                            translate}}</p>
                    </div>

                    <div class="ontario-checkboxes"
                        [ngClass]="{'ontario-checkboxes__single-option': inputForm.touched && inputForm.invalid}">
                        <lib-inline-validaton *ngIf="inputForm.touched && inputForm.invalid" [fieldName]="'checkboxes'"
                            [message]="'tsoa-cfs-public.alerts.error.inputForm.requireCheckboxesToBeChecked'"></lib-inline-validaton>

                        <div class="ontario-checkboxes__item"
                            [ngClass]="{'ontario-checkboxes__error': inputForm.touched && inputForm.invalid}">
                            <input class="ontario-checkboxes__input" type="checkbox" id="towDriver" name="towDriver"
                                [value]="complaintSubjectOptions['Tow Driver']" formControlName="towDriverInput"
                                (change)="multiSelectChange($event.target)">
                            <label class="ontario-checkboxes__label"
                                for="towDriver">{{'tsoa-cfs-public.firstFormsPage.Tow Driver' | translate}}</label>
                        </div>
                        <div class="ontario-checkboxes__item"
                            [ngClass]="{'ontario-checkboxes__error': inputForm.touched && inputForm.invalid}">
                            <input class="ontario-checkboxes__input" type="checkbox" id="vehicleTowingCompany"
                                name="vehicleTowingCompany" [value]="complaintSubjectOptions['Vehicle Towing Company']"
                                formControlName="vehicleTowingCompanyInput" (change)="multiSelectChange($event.target)">
                            <label class="ontario-checkboxes__label"
                                for="vehicleTowingCompany">{{'tsoa-cfs-public.firstFormsPage.Tow Company' |
                                translate}}</label>
                        </div>
                        <div class="ontario-checkboxes__item"
                            [ngClass]="{'ontario-checkboxes__error': inputForm.touched && inputForm.invalid}">
                            <input class="ontario-checkboxes__input" type="checkbox" id="vehicleStorageCompany"
                                name="vehicleStorageCompany"
                                [value]="complaintSubjectOptions['Vehicle Storage Company']"
                                formControlName="vehicleStorageCompanyInput"
                                (change)="multiSelectChange($event.target)">
                            <label class="ontario-checkboxes__label"
                                for="vehicleStorageCompany">{{'tsoa-cfs-public.firstFormsPage.Vehicle Storage Company' |
                                translate}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div class="ontario-row" style="padding-bottom: 2rem;">
            <div class='ontario-columns ontario-small-12 ontario-medium-12 ontario-large-12'>
                <h2 style="max-width: fit-content; padding-bottom: 1rem;"> {{'tsoa-cfs-public.firstFormsPage.Why did you
                    interact with the company or driver?' | translate}}</h2>
                <lib-inline-validaton *ngIf="interactionType.touched && interactionType.invalid"
                    [fieldName]="'checkboxes'"
                    [message]="'tsoa-cfs-public.alerts.error.firstFormsPageSelect'"></lib-inline-validaton>
                <label for="interactionType">
                    <b> {{'tsoa-cfs-public.firstFormsPage.Reason for the interaction' | translate }} </b>
                    ({{ 'common.required' | translate }})
                </label>
                <select id="interactionType" name="reasonDropdown" class="ontario-input ontario-dropdown"
                    (change)="updateSelectedReason($event.target)" formControlName="interactionType"
                    [ngClass]="{'ontario-input__error': interactionType.touched && interactionType.invalid }">
                    <option value=""
                        [disabled]="reasonForInteractionOptions.includes(complainAbout.typeOfInteraction) ? true : false">
                        {{'common.select' | translate}}
                    </option>
                    <option *ngFor="let option of reasonForInteractionOptions"
                        [selected]="option === complainAbout.typeOfInteraction ? true : false" [value]="option">
                        {{'tsoa-cfs-public.firstFormsPage.' + option | translate}}</option>
                </select>
            </div>
        </div>


        <div class="ontario-row">
            <div class='ontario-columns ontario-small-12 ontario-medium-10 ontario-large-10'>
                <h2>{{'tsoa-cfs-public.firstFormsPage.What is the complaint about?' | translate}}</h2>
                <div>
                    <h3 style="margin: 0;">{{'tsoa-cfs-public.firstFormsPage.Type of complaint' | translate}}</h3>
                    <p style="margin-top: 1.25rem;">{{'tsoa-cfs-public.firstFormsPage.Select all that apply' |
                        translate}}</p>
                </div>

                <div formArrayName="complaintOptions" style="padding-top: 1rem;">
                    <lib-inline-validaton *ngIf="atLeastOneCheckboxTouched() && complaintOptions.invalid"
                        [fieldName]="'checkboxes'"
                        [message]="'tsoa-cfs-public.alerts.error.complaintOptions.requireCheckboxesToBeChecked'"></lib-inline-validaton>


                    <div class="ontario-checkboxes grid-container"
                        [ngClass]="{'ontario-checkboxes__single-option': atLeastOneCheckboxTouched() && complaintOptions.invalid}">

                        <div class="ontario-checkboxes__item grid-item"
                            *ngFor="let checkbox of complaintOptions.controls; index as i"
                            [ngClass]="{'ontario-checkboxes__error': atLeastOneCheckboxTouched() && complaintOptions.invalid}">
                            <input type="checkbox" class="ontario-checkboxes__input"
                                (change)="updateTypeofComplaintMultiSelect($event.target)" formControlName="{{i}}"
                                [name]="typeOfComplaintOptions[i]" [id]="typeOfComplaintOptions[i]"
                                value="{{typeOfComplaintOptions[i]}}">
                            <label class="ontario-checkboxes__label" [for]="typeOfComplaintOptions[i]">
                                {{'tsoa-cfs-public.firstFormsPage.' + typeOfComplaintOptions[i] | translate}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>