import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileWidget } from 'projects/common-lib/src/public-api';

@Component({
  selector: 'lib-file-widget',
  templateUrl: './file-widget.component.html',
  styleUrls: ['./file-widget.component.scss']
})
export class FileWidgetComponent implements OnInit {
  @Input() fileWidget!: FileWidget;
  @Output() thisOneDad = new EventEmitter<string>()

  displayName!: string;

  ngOnInit() {
    if (this.fileWidget.fileName.length > 30) {
      const split = this.fileWidget.fileName.split('.');

      const extension = split.pop();
      const filename = this.fileWidget.fileName.substring(0, 25);

      this.displayName = filename + "... ." + extension;
    }
    else {
      this.displayName = this.fileWidget.fileName;
    }
  }

  deleteDocument() {
    this.thisOneDad.emit(this.fileWidget.UUID);
  }
}
