import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CommonLibService } from 'projects/common-lib/src/public-api';

@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

export class HeaderComponent {

  @Input() pageTitle = '';
  @Input() showLanguageToggle = false;
  @Input() showLogOutToggle = false;
  @Output() logOut = new EventEmitter<void>;

  constructor(
    private router: Router,
    private commonLibService: CommonLibService
  ) { }

  isFrench = this.router.url.includes('fr');
  buttonPropLanguage: string = '';

  ngOnInit() {
    // we need to actually set the current language in state when we first load the page
    this.commonLibService.setCurrentLanguage(this.isFrench === true ? 'fr' : '')
    this.buttonPropLanguage = this.commonLibService.getCurrentLanguage() === '' ? 'fr' : 'en';
  }

  languageToggle() {
    const currentUrl = this.router.url.slice(1);

    if (!location.href.includes('fr')) {
      this.commonLibService.setCurrentLanguage('fr');
      this.buttonPropLanguage = 'en';
      this.router.navigateByUrl(`fr${this.router.url}`);
    }
    else {
      this.commonLibService.setCurrentLanguage('');
      this.router.navigate([currentUrl.slice(2)]);
      this.buttonPropLanguage = 'fr'; //property of the language change button
    }
  }

  logOutToggle() {
    // TODO: fire event
    this.logOut.emit();
  }
}

