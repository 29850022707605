<div>
    <div class="ontario-row">
        <div class="ontario-columns ontario-small-12">
            <button class="ontario-button ontario-button--tertiary back-button" (click)="goBack()" type="tertiary">
                <svg class='dpp-icon-back ontario-icon' aria-hidden='true' focusable='false' sol:category='primary'
                    viewBox='0 0 24 24' preserveAspectRatio='xMidYMid meet'>
                    <use xlink:href='#ontario-icon-chevron-left'></use>
                </svg>
                {{ 'common.back' | translate }}
            </button>
        </div>
    </div>
    <lib-alert-panel id="alertPanel" (scrollToValidation)="scrollToValidation($event)" *ngIf="cancelClicked"
        (cancelEvent)="cancel($event)" [alertType]="'cancel'" [alertTitle]="'common.alerts.cancel.title'"
        [alertMessage]="['common.alerts.cancel.sub-title']"></lib-alert-panel>

    <lib-alert-panel id="alertPanel" (scrollToValidation)="scrollToValidation($event)"
        *ngIf="!inputForm.valid && hasErrors" [alertType]="'error'" [alertTitle]="'common.alerts.error.title'"
        [alertMessage]="['common.alerts.error.sub-title']" [alertErrorList]="listOfErrors"></lib-alert-panel>

    <div class='ontario-row'>
        <div class='ontario-columns ontario-small-12'>
            <h2 class="ontario-h1">{{ 'tsoa-cfs-public.contactPage.title' | translate }}</h2>
        </div>
    </div>
    <div class='ontario-row'>
        <div class='ontario-columns ontario-small-12 ontario-medium-12 ontario-large-12'>
            <div class="ontario-form-group">
                <form [formGroup]="inputForm">
                    <label class="ontario-label" for="fullNameInput">
                        {{ 'tsoa-cfs-public.contactPage.name' | translate }}
                        <span class="ontario-label__flag">({{ 'common.required' | translate }})</span>
                    </label>
                    <p class="ontario-hint" id="contactPage-fullname-hint">{{ 'tsoa-cfs-public.contactPage.name
                        hint' |
                        translate }}</p>

                    <lib-inline-validaton *ngIf="fullNameInput.touched && fullNameInput.errors?.required"
                        [fieldName]="'fullNameInput'"
                        [message]="'tsoa-cfs-public.alerts.error.fullNameInput.required'"></lib-inline-validaton>
                    <lib-inline-validaton *ngIf="fullNameInput.touched && fullNameInput.errors?.maxlength"
                        [fieldName]="'fullNameInput'"
                        [message]="'tsoa-cfs-public.alerts.error.fullNameInput.maxlength'"></lib-inline-validaton>
                    <lib-inline-validaton *ngIf="fullNameInput.touched && fullNameInput.errors?.pattern"
                        [fieldName]="'fullNameInput'"
                        [message]="'tsoa-cfs-public.alerts.error.fullNameInput.pattern'"></lib-inline-validaton>

                    <input class="ontario-input" type="text" id="fullNameInput" maxlength="100"
                        [ngClass]="{'ontario-input__error': fullNameInput.touched && fullNameInput.invalid }"
                        formControlName="fullNameInput" (ngModelChange)="checkInvalid('fullNameInput')" />

                    <label class="ontario-label" for="phoneNumberInput">
                        {{ 'tsoa-cfs-public.contactPage.phone' | translate }}
                    </label>
                    <p class="ontario-hint" id="contactPage-phonenumber-hint">
                        {{ 'tsoa-cfs-public.contactPage.phone hint' | translate }}
                    </p>
                    <input class="ontario-input" type="text" id="phoneNumberInput" mask="000-000-0000"
                        formControlName="phoneNumberInput" [validation]="false" />

                    <label class="ontario-label" for="emailInput">
                        {{ 'tsoa-cfs-public.contactPage.email' | translate }}
                        <span class="ontario-label__flag">({{ 'common.required' | translate }})</span>
                    </label>

                    <lib-inline-validaton *ngIf="emailInput.touched && emailInput.errors?.required"
                        [fieldName]="'emailInput'"
                        [message]="'tsoa-cfs-public.alerts.error.emailInput.required'"></lib-inline-validaton>
                    <lib-inline-validaton *ngIf="emailInput.touched && emailInput.errors?.pattern"
                        [fieldName]="'emailInput'"
                        [message]="'tsoa-cfs-public.alerts.error.emailInput.pattern'"></lib-inline-validaton>
                    <input class="ontario-input" type="text" id="emailInput"
                        [ngClass]="{'ontario-input__error': emailInput.touched && emailInput.invalid }" maxlength="100"
                        (keyup)="lookUpDomain($event)" formControlName="emailInput"
                        (ngModelChange)="checkInvalid('emailInput')" />

                    <!-- <label class="ontario-label" for="organizationInput">
                        {{ 'tsoa-cfs-public.contactPage.organization' | translate }}
                        <span class="ontario-label__flag">({{ 'common.required' | translate }})</span>
                    </label>
                    <lib-inline-validaton *ngIf="organizationInput.touched && organizationInput.invalid"
                        [fieldName]="'organizationInput'"
                        [message]="'tsoa-cfs-public.alerts.error.organizationInput.required'"></lib-inline-validaton>
                    <select class="ontario-input ontario-dropdown" id="organizationInput"
                        [ngClass]="{'ontario-input__error': organizationInput.touched && organizationInput.invalid }"
                        (change)="updateSelected($event.target)" formControlName="organizationInput"
                        (ngModelChange)="checkInvalid('organizationInput')">

                        <option value="" [disabled]="this.contactInfo.organizationName !== null">
                            {{'common.select' | translate }}
                        </option>
                        <option *ngFor="let organization of organizationOptions"
                            [selected]="organization === this.orgDetected" [value]="organization">
                            {{'tsoa-cfs-public.contactPage.organization-options.' + organization | translate}}
                        </option>
                    </select> -->
                    <div class='ontario-row'>
                        <div class='ontario-columns ontario-small-12 ontario-medium-12 ontario-large-8'>
                            <button class="ontario-button ontario-button--primary" type="button" (click)="goNext()">
                                {{'common.next' | translate }}
                            </button>
                            <button class="ontario-button ontario-button--secondary" type="secondary"
                                (click)="goCancel()">
                                {{'common.cancel' | translate }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>