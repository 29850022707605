<header class="ontario-header platform-header">
    <div class="ontario-columns columns-no-padding">
        <div class="ontario-header__logo-container ontario-columns ontario-small-6 header-container">
            <a *ngIf="!isFrench" href="https://www.ontario.ca/page/government-ontario" target="_blank">
                <img class="image-styling" src="assets/ontario-design-system/logos/ontario-logo--desktop.svg"
                    width="100" height="25" role="img" alt="ontario logo" />
            </a>
            <a *ngIf="isFrench" href="https://www.ontario.ca/fr/page/gouvernement-de-lontario" target="_blank">
                <img class="image-styling" src="assets/ontario-design-system/logos/ontario-logo--desktop.svg"
                    width="100" height="25" role="img" alt="ontario logo" />
            </a>
        </div>
        <div *ngIf="showLanguageToggle || showLogOutToggle"
            class='ontario-columns ontario-small-6 ontario-application-header__lang-toggle'>
            <a tabindex="0" (keypress)="languageToggle()" *ngIf="showLanguageToggle" [lang]="buttonPropLanguage"
                (click)="languageToggle()"
                class='ontario-header__language-toggler ontario-header-button ontario-header-button--without-outline'>
                {{ 'common.language' | translate }}
            </a>
            <a tabindex="0" *ngIf="showLogOutToggle" (keypress)="logOutToggle()" (click)="logOutToggle()"
                class='ontario-header__language-toggler ontario-header-button ontario-header-button--without-outline'>
                Logout
            </a>
        </div>
    </div>
</header>

<section class="ontario-application-subheader flex">
    <div class="ontario-row subheader-width align-center">
        <div class="ontario-columns ontario-small-12">
            <h1 class="my-subheader">{{ pageTitle | translate }}</h1>
        </div>
    </div>
</section>