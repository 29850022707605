import { Component } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { StateManagerService } from './services/state-manager.service';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { CommonLibService, PublicRouteEnum, scrollToTop } from 'projects/common-lib/src/public-api';
import { ComplaintService } from './services/complaint.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'cfs-public';

  showSessionTimeout = false;
  currentSessionTimer = new Date();

  constructor(
    private userIdle: UserIdleService,
    private stateManagerService: StateManagerService,
    private commonLibService: CommonLibService,
    private complaintService: ComplaintService,
    private router: Router
  ) { }

  ngOnInit() {
    if (!environment.disableSessionWarning) {

      let addedTime = this.userIdle.getConfigValue().idle;
      this.currentSessionTimer.setMinutes(this.currentSessionTimer.getMinutes() + (addedTime === undefined ? 30 : addedTime / 60)); // default to 30 if idle time is undefined
      // console.log("current session timer: " + this.currentSessionTimer.toISOString());

      //Start watching for user inactivity.
      this.userIdle.startWatching();

      // Start watching when user idle is starting.
      this.userIdle.onTimerStart().subscribe(() => {
        this.showSessionTimeout = true;
        scrollToTop();
      });

      // Start watch when time is up.
      this.userIdle.onTimeout().subscribe(() => {
        // end session
        this.endUserSession();
      });

      // detect if user is idle or not
      this.userIdle.onIdleStatusChanged().subscribe((isIdle) => {
        let localdate = new Date();
        // console.log("localdate: " + localdate.toISOString() + ", storedtimer: " + this.currentSessionTimer.toISOString())
        // console.log("status changed? " + isIdle)

        const differenceInMilliseconds = this.currentSessionTimer.getTime() - localdate.getTime();

        const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));
        // console.log("diff in mins: " + differenceInMinutes);

        // only call the extend session if we are 2 mins away from the session time out and user is not idle anymore
        if (isIdle && this.showSessionTimeout == false && differenceInMinutes < 2) {
          this.extendSession(true);
        }
      });
    }
  }

  ngOnDestroy() {
    if (!environment.disableSessionWarning) {
      this.userIdle.stopWatching();
    }
  }

  extendSession(input: boolean) {
    if (input) {
      // extend session

      // console.log("the user session id is: " + this.stateManagerService.getSessionUUID());

      if (this.stateManagerService.getSessionUUID() != '') {
        this.complaintService.extendUserSession(this.stateManagerService.getSessionUUID()).subscribe({
          next: (result) => {
            // console.log(new Date(result).toISOString());
            this.currentSessionTimer = new Date(result);
            this.userIdle.resetTimer();
            this.showSessionTimeout = false;
          },
          error: (error) => {
            console.error(error);
          },
        });
      } else {
        console.error("sessionUUID is empty");
      }
    } else {
      // end session
      this.endUserSession();
    }
  }

  endUserSession() {
    // end session
    this.userIdle.resetTimer();
    this.showSessionTimeout = false;

    // delete all entered data
    this.stateManagerService.clearStateObject();


    // then go to home page
    this.stateManagerService.setRouteStep(PublicRouteEnum.LANDING_PAGE);
    this.router.navigate([this.commonLibService.getCurrentLanguage() + `/${environment.rootRoute}/`]);
  }
}
