import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Comment } from 'projects/common-lib/src/public-api';

@Component({
  selector: 'lib-mto-comment-dialog',
  templateUrl: './mto-comment-dialog.component.html',
  styleUrls: ['./mto-comment-dialog.component.scss']
})
export class MtoCommentDialogComponent {

  mtoComment!: Comment;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.mtoComment = data.mtoComment;
  }

}
