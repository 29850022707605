import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { RecaptchaComponent } from 'ng-recaptcha';
import { environment } from 'projects/cfs-public/src/environments/environment';
import { CommonLibService, PublicRouteEnum, scrollToTop, scrollToValidation, scrollToAlertPanel } from 'projects/common-lib/src/public-api';
import { StateManagerService } from '../../services/state-manager.service';
import { ComplaintService } from '../../services/complaint.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit, OnDestroy {


  recaptchaSub!: Subscription;
  @ViewChild(RecaptchaComponent) reCaptcha: any;

  reCaptchaKey = environment.recaptchaSiteKey;
  disableRecaptcha = environment.disableRecaptcha;
  reacaptchaError = false;
  showLoadingIndicator = false;

  isClicked = false;
  isCollapsed = true;
  isChecked = false;
  listOfErrors: string[] = [];
  display = 'none';
  spanClass = 'ontario-accordion__button-icon--open';
  chevron = '#ontario-icon-chevron-down';
  isAccordionExpanded = false;

  scrollToValidation = scrollToValidation;

  constructor(
    private stateManagerService: StateManagerService,
    private commonLibService: CommonLibService,
    private titleService: Title,
    private router: Router,
    private complaintService: ComplaintService
  ) {
    if (this.router.url.includes('fr/tssea')) {
      this.titleService.setTitle('Page de destination | BSREV'); // French tab title
    }
    else {
      this.titleService.setTitle('Landing Page | TVSO'); // English tab title
    }
  }

  ngOnDestroy(): void {
    this.recaptchaSub?.unsubscribe();
  }

  ngOnInit(): void {
    if (this.reCaptcha) {
      this.reCaptcha.reset();
    }
    if (this.disableRecaptcha) {
      this.resolved('fakeToken');
    }
  }

  toggle() {
    this.isCollapsed = !this.isCollapsed;
    if (this.isCollapsed) {
      this.display = 'none';
      this.spanClass = 'ontario-accordion__button-icon--open';
      this.chevron = '#ontario-icon-chevron-down';
      this.isAccordionExpanded = false;
    } else {
      this.display = 'block';
      this.spanClass = 'ontario-accordion__button-icon--close';
      this.chevron = '#ontario-icon-chevron-up';
      this.isAccordionExpanded = true;
    }
  }

  goNext() {
    this.isClicked = true;
    this.reacaptchaError = false;
    if (this.isChecked) {
      // execute recaptcha
      if (!environment.disableRecaptcha && environment.recaptchaSiteKey) {
        this.reCaptcha.execute();
      } else {
        // means we have disabled recaptcha so by pass it
        this.routeToNextPage();
      }
    }
    if (this.listOfErrors.length == 0) {
      this.listOfErrors.push('termsConditionsError');
      scrollToAlertPanel();
    }
  }

  resolved(captchaResponse: string) {
    this.showLoadingIndicator = true;
    // send to backend for validation
    this.recaptchaSub = this.complaintService.verifyReCaptcha(captchaResponse).subscribe({
      next: (response) => {
        this.showLoadingIndicator = false;
        this.stateManagerService.setSessionUUID(response.sessionUUID);
        // navigate to next page if recaptcha is good!
        this.routeToNextPage();
      },
      error: (error) => {
        this.showLoadingIndicator = false;
        this.reacaptchaError = true;
        scrollToTop();
        console.error(error);
      },
      complete: () => {
        console.log("recaptcha complete");
      }
    });
  }

  routeToNextPage() {
    if (this.isChecked) {
      this.stateManagerService.setRouteStep(PublicRouteEnum.CONTACT_INFO);
      this.router.navigate([this.commonLibService.getCurrentLanguage() + '/' + `${environment.rootRoute}` + '/', 'contact-info']);
    }
  }

}
