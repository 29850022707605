import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';

/* Guards */
import { LocalizationGuard } from 'projects/common-lib/src/lib/translation/localization.guard';

/* Components */
import { LandingPageComponent } from './page-components/landing-page/landing-page.component';
import { PageNotFoundComponent } from 'projects/common-lib/src/lib/common-components/page-not-found/page-not-found.component';
import { ContactInfoComponent } from './page-components/contact-info/contact-info.component';
import { IncidentInfoComponent } from './page-components/incident-info/incident-info.component';
import { EndPageComponent } from './page-components/end-page/end-page.component';
import { RouteGuardService } from './services/route-guard.service';
import { PublicRouteEnum } from 'projects/common-lib/src/public-api';

const routes: Routes = [
  {
    path: '',
    redirectTo: `${environment.rootRoute}`,
    pathMatch: 'full'
  },
  {
    path: `${environment.rootRoute}`,
    component: LandingPageComponent,
    canActivate: [LocalizationGuard, RouteGuardService],
    data: { expectedStep: PublicRouteEnum.LANDING_PAGE },
  },
  {
    path: `fr/${environment.rootRoute}`,
    component: LandingPageComponent,
    canActivate: [LocalizationGuard, RouteGuardService],
    data: { expectedStep: PublicRouteEnum.LANDING_PAGE },
  },
  {
    path: `${environment.rootRoute}/contact-info`,
    component: ContactInfoComponent,
    canActivate: [LocalizationGuard, RouteGuardService],
    data: { expectedStep: PublicRouteEnum.CONTACT_INFO },
  },
  {
    path: `fr/${environment.rootRoute}/contact-info`,
    component: ContactInfoComponent,
    canActivate: [LocalizationGuard, RouteGuardService],
    data: { expectedStep: PublicRouteEnum.CONTACT_INFO },
  },
  {
    path: `${environment.rootRoute}/enter-incident-info`,
    component: IncidentInfoComponent,
    canActivate: [LocalizationGuard, RouteGuardService],
    data: { expectedStep: PublicRouteEnum.ENTER_INCIDENT_INFO },
  },
  {
    path: `fr/${environment.rootRoute}/enter-incident-info`,
    component: IncidentInfoComponent,
    canActivate: [LocalizationGuard, RouteGuardService],
    data: { expectedStep: PublicRouteEnum.ENTER_INCIDENT_INFO },
  },
  {
    path: `${environment.rootRoute}/submit-complaint`,
    component: EndPageComponent,
    canActivate: [LocalizationGuard, RouteGuardService],
    data: { expectedStep: PublicRouteEnum.SUBMIT_COMPLAINT },
  },
  {
    path: `fr/${environment.rootRoute}/submit-complaint`,
    component: EndPageComponent,
    canActivate: [LocalizationGuard, RouteGuardService],
    data: { expectedStep: PublicRouteEnum.SUBMIT_COMPLAINT },
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })], // for scrolling to top of page on route change
  exports: [RouterModule]
})
export class AppRoutingModule { }
