import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import incidentPage1Options from "projects/common-lib/src/lib/assets/jsonData/incidentPage1Options.json";
import { Router } from '@angular/router';
import { Errors, scrollToAlertPanel, scrollToValidation } from 'projects/common-lib/src/public-api';
import { StateManagerService } from '../../../services/state-manager.service';

@Component({
  selector: 'app-incident-info-page3',
  templateUrl: './incident-info-page3.component.html',
  styleUrls: ['./incident-info-page3.component.scss'],
})
export class IncidentInfoPage3Component implements OnInit {
  @Input() eventsSubject!: Subject<number>;
  @Output() formValidityChanged = new EventEmitter<Errors>();
  complaintSubjectOptions = incidentPage1Options.complaintSubjectOptions;
  complainAboutSubjects!: string[];
  parentError: string[] = ['add info required'];
  towErrorList: string[] = [];
  storageErrorList: string[] = [];
  childFormValid = true;

  renderAddInfo = true;
  renderVehicleStorageFirst = false;
  checked = false;

  alreadySelectedTow = false;
  alreadySelectedVeh = false;

  shouldShowStorageInfo!: boolean | null;
  shouldShowVehicleTowingOrTowDriverInfo!: boolean | null;

  pageErrors: Errors = {
    validity: true,
    listOfErrors: [],
  };

  scrollToValidation = scrollToValidation;

  constructor(
    private stateManagerService: StateManagerService,
    private titleService: Title,
    private router: Router
  ) {
    if (this.router.url.includes('fr/tssea')) {
      this.titleService.setTitle('Entreprise et conducteur | BSREV'); // French tab title
    }
    else {
      this.titleService.setTitle('Company and driver | TVSO'); // English tab title
    }
  }

  ngOnInit(): void {
    this.complainAboutSubjects = this.stateManagerService.getComplainAbout().complaintSubject;
    this.shouldShowStorageInfo = this.stateManagerService.getShowStorageInfo();
    this.shouldShowVehicleTowingOrTowDriverInfo = this.stateManagerService.getShowVehicleTowingOrTowDriverInfo();

    this.alreadySelectedTow = this.complainAboutSubjects.some(e => e.includes("TOW"));
    this.alreadySelectedVeh = this.complainAboutSubjects.some(e => e.includes("VEH"));

    // Vehicle storage company is the first component to be displayed
    this.renderVehicleStorageFirst =
      this.alreadySelectedVeh &&
      !this.alreadySelectedTow;

    // Don't show add buttons
    if (this.alreadySelectedTow && this.alreadySelectedVeh) {
      this.renderAddInfo = false;
      this.shouldShowStorageInfo = true;
      this.shouldShowVehicleTowingOrTowDriverInfo = true;
      this.parentError.pop();
      this.checked = true;
    }
    if ((this.shouldShowStorageInfo !== null && this.alreadySelectedTow) || (this.shouldShowVehicleTowingOrTowDriverInfo !== null && this.alreadySelectedVeh)) {
      this.parentError.pop();
      this.checked = true;
    }
  }

  addInfo() {

    this.parentError.pop();
    this.checked = true;
    this.pageErrors.listOfErrors = [...this.parentError, ...this.storageErrorList, ...this.towErrorList];
    this.pageErrors.validity = this.childFormValid && this.checked;

    this.formValidityChanged.emit(this.pageErrors);

    // Add tow info
    if (this.alreadySelectedVeh) {
      this.shouldShowVehicleTowingOrTowDriverInfo = true;
      this.stateManagerService.setShowVehicleTowingOrTowDriverInfo(this.shouldShowVehicleTowingOrTowDriverInfo);
    }
    // Add storage info
    else if (this.alreadySelectedTow) {
      this.shouldShowStorageInfo = true;
      this.stateManagerService.setShowStorageInfo(this.shouldShowStorageInfo);
    }
  }

  clickNo() {
    this.parentError.pop();
    this.checked = true;
    this.pageErrors.listOfErrors = [...this.parentError, ...this.storageErrorList, ...this.towErrorList];
    this.formValidityChanged.emit(this.pageErrors);
    this.pageErrors.validity = this.childFormValid && this.checked;

    if (this.renderVehicleStorageFirst) {
      this.shouldShowVehicleTowingOrTowDriverInfo = false;
      this.stateManagerService.setShowVehicleTowingOrTowDriverInfo(this.shouldShowVehicleTowingOrTowDriverInfo);
    } else {
      this.shouldShowStorageInfo = false;
      this.stateManagerService.setShowStorageInfo(this.shouldShowStorageInfo);
    }
  }


  checkForWebsiteLinkErrors(): void {

    const websiteLinkErrorArray = ['towingCompanyNameInput.pattern', 'nameOnTruckInput.pattern', 'towDriverNameInput.pattern', 'vehicleStorageCompanyNameInput.pattern', 'addressOfStoredVehicleInput.pattern'];

    if (this.pageErrors.listOfErrors.some(r => websiteLinkErrorArray.includes(r))) {

      // filter all website link errors out
      this.pageErrors.listOfErrors = this.pageErrors.listOfErrors.filter((x) => {
        return !websiteLinkErrorArray.includes(x);
      });

      // add one website link error
      this.pageErrors.listOfErrors.push('No field can contain a website link');
    }
  }

  onFormValidityChanged(errors: Errors) {
    if (errors.childName === 'driver') {
      this.towErrorList = errors.listOfErrors;
    } else if (errors.childName === 'storage') {
      this.storageErrorList = errors.listOfErrors;
    }

    this.pageErrors.listOfErrors = [...this.parentError, ...this.storageErrorList, ...this.towErrorList];

    this.checkForWebsiteLinkErrors();

    this.pageErrors.validity = errors.validity && this.childFormValid && this.checked;
    this.formValidityChanged.emit(this.pageErrors);
    this.childFormValid = errors.validity;

    scrollToAlertPanel();
  }
}
