<div class="ontario-row">
    <div class='ontario-columns ontario-small-12 ontario-medium-12 ontario-large-12'>

        <lib-alert-panel id="alertPanel" tabindex="0" *ngIf="reacaptchaError" [alertType]="'error'"
            [alertTitle]="'common.alerts.serviceError.title'"
            [alertMessage]="['common.alerts.serviceError.sub-title']"></lib-alert-panel>

        <lib-alert-panel id="alertPanel" tabindex="0" *ngIf="(isClicked && !isChecked)" [alertType]="'error'"
            [alertTitle]="'common.alerts.error.title'" [alertMessage]="['common.alerts.error.sub-title']"
            [alertErrorList]="listOfErrors" (scrollToValidation)="scrollToValidation($event)"></lib-alert-panel>

        <div>
            <h2 class="ontario-h1">{{ 'tsoa-cfs-public.landingPage.complainAbout' | translate }}</h2>
            <p>{{ 'tsoa-cfs-public.landingPage.info' | translate }}</p>
            <p>{{ 'tsoa-cfs-public.landingPage.exampleInfo' | translate }}</p>
            <ul>
                <li>{{ 'tsoa-cfs-public.landingPage.example1' | translate }}</li>
                <li>{{ 'tsoa-cfs-public.landingPage.example2' | translate }}</li>
                <li>{{ 'tsoa-cfs-public.landingPage.example3' | translate }}</li>
                <li>{{ 'tsoa-cfs-public.landingPage.example4' | translate }}</li>
            </ul>
        </div>

        <div>
            <h2 class="ontario-h1">{{ 'tsoa-cfs-public.landingPage.expect' | translate }}</h2>
            <p>{{ 'tsoa-cfs-public.landingPage.expectInfo' | translate }}</p>
        </div>

        <div>
            <h2 class="ontario-h1">{{ 'tsoa-cfs-public.landingPage.collectionNotice' | translate }}</h2>
            <p>{{ 'tsoa-cfs-public.landingPage.collectionInfo' | translate }}</p>
        </div>

        <div>
            <h2 class="ontario-h1">{{ 'tsoa-cfs-public.landingPage.termsConditions' | translate }}</h2>
            <div id='accordion-1'>
                <p class='ontario-accordion-heading'>
                    <button class='ontario-accordion__button' id='accordion-button-id-1'
                        aria-controls='accordion-content-1' attr.aria-expanded='{{isAccordionExpanded}}'
                        data-toggle='ontario-collapse' (click)="toggle()">
                        <span ngClass='spanClass' id="open">
                            <svg class="ontario-icon" alt="" aria-hidden="false" focusable="false"
                                sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                <use [attr.href]="chevron"></use>
                            </svg>
                        </span>

                        {{ 'tsoa-cfs-public.landingPage.whatTermsConditions' | translate }}
                    </button>
                </p>
                <section class='ontario-accordion__content' id='accordion-content-1'
                    aria-labelledby='accordion-button-id-1' attr.aria-hidden='{{isCollapsed}}'
                    data-toggle='ontario-expander-content' [ngStyle]="{ 'display': display }">
                    <p>{{ 'tsoa-cfs-public.landingPage.tc1' | translate }}</p>
                    <ul>
                        <li>{{ 'tsoa-cfs-public.landingPage.tc2' | translate }} <a
                                href="https://www.ontario.ca/page/terms-use" target="_blank">{{
                                'tsoa-cfs-public.landingPage.tc2.1' | translate }}</a>{{
                            'tsoa-cfs-public.landingPage.tc2.2' | translate }}</li>
                        <li>{{ 'tsoa-cfs-public.landingPage.tc3' | translate }}</li>
                        <li>{{ 'tsoa-cfs-public.landingPage.tc5' | translate }}</li>
                        <li>{{ 'tsoa-cfs-public.landingPage.tc6' | translate }}</li>
                        <li>{{ 'tsoa-cfs-public.landingPage.tc7' | translate }}</li>
                        <li>{{ 'tsoa-cfs-public.landingPage.tc10' | translate }}</li>
                        <li>{{ 'tsoa-cfs-public.landingPage.tc11' | translate }}</li>
                        <ul>
                            <li>{{ 'tsoa-cfs-public.landingPage.tc11.1' | translate }}</li>
                            <li>{{ 'tsoa-cfs-public.landingPage.tc11.2' | translate }}</li>
                        </ul>
                    </ul>
                    <p>{{ 'tsoa-cfs-public.landingPage.tc12' | translate }}</p>
                    <ul>
                        <li>{{ 'tsoa-cfs-public.landingPage.tc13' | translate }}</li>
                        <ul>
                            <li>{{ 'tsoa-cfs-public.landingPage.tc13.1' | translate }}</li>
                        </ul>
                        <li>{{ 'tsoa-cfs-public.landingPage.tc14' | translate }}</li>
                    </ul>
                </section>
            </div>
            <div class="ontario-checkboxes" [ngClass]="{'ontario-checkboxes__single-option': isClicked && !isChecked}">
                <lib-inline-validaton *ngIf="isClicked && !isChecked" [fieldName]="'checkboxes'"
                    [message]="'tsoa-cfs-public.alerts.error.termsConditionsError'"></lib-inline-validaton>
                <div class="ontario-checkboxes__item"
                    [ngClass]="{'ontario-checkboxes__error': isClicked && !isChecked}">
                    <input class="ontario-checkboxes__input" type="checkbox" id="termsConditionsError" value="option-1"
                        [(ngModel)]="isChecked" />
                    <label class="ontario-checkboxes__label" for="termsConditionsError">
                        {{ 'tsoa-cfs-public.landingPage.termsConditionsAgree' | translate }}
                    </label>
                </div>
            </div>

            <div>
                <h2 class="ontario-h1">{{ 'tsoa-cfs-public.landingPage.contact' | translate }}</h2>
                <p>{{ 'tsoa-cfs-public.landingPage.contactInfo' | translate }}</p>
            </div>

            <re-captcha *ngIf='reCaptchaKey && !disableRecaptcha' [siteKey]='reCaptchaKey' id='re-captcha'
                #reCaptcha='reCaptcha' size='invisible' (resolved)='resolved($event)'>
                <label for="re-captcha"></label></re-captcha>

            <lib-loading-indicator *ngIf="showLoadingIndicator" [fullScreen]="true"></lib-loading-indicator>

            <div class='dpp-rin-form-block'>

                <button class="ontario-button ontario-button--primary" type="button" (click)="goNext()">{{
                    'common.next'
                    |
                    translate
                    }}
                </button>
            </div>

        </div>
    </div>
</div>