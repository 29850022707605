<div class="ontario-row">
    <div class='ontario-columns ontario-small-12 ontario-medium-12 ontario-large-12'>
        <form [formGroup]="inputForm">
            <div class="towCompanyInfo">
                <h2>{{ 'tsoa-cfs-public.thirdFormsPage.Tow company info' | translate }}</h2>
                <label for="cvorNumInput">
                    <h3>{{ 'tsoa-cfs-public.thirdFormsPage.Cvor tow num' | translate }}</h3>
                </label>
                <p class="ontario-hint" id="">{{ 'tsoa-cfs-public.thirdFormsPage.Cvor example' | translate }}</p>
                <input class="ontario-input" type="text" id="cvorNumInput" mask="000-000-000" [validation]="false"
                    formControlName="cvorNumInput" />

                <label for="towingCompanyNameInput">
                    <h3>{{ 'tsoa-cfs-public.thirdFormsPage.Tow company name' | translate }}</h3>
                </label>
                <lib-inline-validaton *ngIf="towingCompanyNameInput.touched && towingCompanyNameInput.invalid"
                    [fieldName]="'towingCompanyNameInput'"
                    [message]="'tsoa-cfs-public.alerts.error.towingCompanyNameInput.pattern'"></lib-inline-validaton>
                <input class="ontario-input" type="text" id="towingCompanyNameInput"
                    formControlName="towingCompanyNameInput" (change)="checkInvalid('towingCompanyNameInput')"
                    maxlength="100"
                    [ngClass]="{'ontario-input__error': towingCompanyNameInput.touched && towingCompanyNameInput.invalid }" />

                <label for="nameOnTruckInput">
                    <h3>{{ 'tsoa-cfs-public.thirdFormsPage.Name on truck' | translate }}</h3>
                </label>
                <lib-inline-validaton *ngIf="nameOnTruckInput.touched && nameOnTruckInput.invalid"
                    [fieldName]="'nameOnTruckInput'"
                    [message]="'tsoa-cfs-public.alerts.error.nameOnTruckInput.pattern'"></lib-inline-validaton>
                <input class="ontario-input" type="text" id="nameOnTruckInput" formControlName="nameOnTruckInput"
                    (change)="checkInvalid('nameOnTruckInput')" maxlength="100"
                    [ngClass]="{'ontario-input__error': nameOnTruckInput.touched && nameOnTruckInput.invalid }" />

                <label for="plateNumInput">
                    <h3>{{ 'tsoa-cfs-public.thirdFormsPage.Plate num' | translate }}</h3>
                </label>
                <lib-inline-validaton *ngIf="plateNumInput.touched && plateNumInput.invalid"
                    [fieldName]="'plateNumInput'"
                    [message]="'tsoa-cfs-public.alerts.error.plateNumInput.pattern'"></lib-inline-validaton>
                <input class="ontario-input" type="text" id="plateNumInput" formControlName="plateNumInput"
                    maxlength="20" (change)="checkInvalid('plateNumInput')"
                    [ngClass]="{'ontario-input__error': plateNumInput.touched && plateNumInput.invalid }" />
            </div>

            <div class="towDriverInfo">
                <h2>{{ 'tsoa-cfs-public.thirdFormsPage.Tow driver info' | translate }}</h2>
                <label for="towDriverCertificateNumInput">
                    <h3>{{ 'tsoa-cfs-public.thirdFormsPage.Tow driver num' | translate }}</h3>
                </label>
                <p class="ontario-hint" id="">{{ 'tsoa-cfs-public.thirdFormsPage.Tow driver num example' | translate }}
                </p>
                <div class="input-group">
                    <div class="ontario-input ontario-input--2-char-width">
                        TD</div>
                    <input class="ontario-input ontario-input--20-char-width" type="text"
                        id="towDriverCertificateNumInput" formControlName="towDriverCertificateNumInput"
                        mask="000-000-000" [validation]="false" />
                </div>

                <label for="towDriverLicenceNumInput">
                    <h3>{{ 'tsoa-cfs-public.thirdFormsPage.Driver licence' | translate }}</h3>
                </label>
                <lib-inline-validaton *ngIf="towDriverLicenceNumInput.touched && towDriverLicenceNumInput.invalid"
                    [fieldName]="'towDriverLicenceNumInput'"
                    [message]="'tsoa-cfs-public.alerts.error.towDriverLicenceNumInput.pattern'"></lib-inline-validaton>
                <input class="ontario-input" type="text" id="towDriverLicenceNumInput"
                    formControlName="towDriverLicenceNumInput" maxlength="20"
                    (change)="checkInvalid('towDriverLicenceNumInput')"
                    [ngClass]="{'ontario-input__error': towDriverLicenceNumInput.touched && towDriverLicenceNumInput.invalid }" />

                <label for="towDriverNameInput">
                    <h3>{{ 'tsoa-cfs-public.thirdFormsPage.Tow driver name' | translate }}</h3>
                </label>
                <lib-inline-validaton *ngIf="towDriverNameInput.touched && towDriverNameInput.invalid"
                    [fieldName]="'towDriverNameInput'"
                    [message]="'tsoa-cfs-public.alerts.error.towDriverNameInput.pattern'"></lib-inline-validaton>
                <input class="ontario-input" type="text" id="towDriverNameInput" formControlName="towDriverNameInput"
                    (change)="checkInvalid('towDriverNameInput')" maxlength="100"
                    [ngClass]="{'ontario-input__error': towDriverNameInput.touched && towDriverNameInput.invalid }" />
            </div>
        </form>

    </div>
</div>