import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent {
  @Input() step = 1;
  @Output() backPressed = new EventEmitter<void>();

  backPress() {
    this.backPressed.emit();
  }
}
