import { Component } from '@angular/core';

@Component({
  selector: 'lib-common-lib',
  template: `
    <p>
      common-lib works!
    </p>
  `,
  styles: [
  ]
})
export class CommonLibComponent {

}
