import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-alert-panel',
  templateUrl: './alert-panel.component.html',
  styleUrls: ['./alert-panel.component.scss'],
})
export class AlertPanelComponent {
  @Input() alertType!: string;

  @Input() needsTranslate = true;

  @Input() alertTitle!: string;

  @Input() alertMessage!: string[];

  @Input() alertErrorList!: string[];

  @Input() referenceId!: string;

  @Input() isPublic = true;

  @Output() cancelEvent = new EventEmitter<boolean>();

  @Output() sessionEvent = new EventEmitter<boolean>();

  @Output() scrollToValidation = new EventEmitter<string>();


  alertIcon = '#ontario-icon-alert-information';

  public setupAlert() {
    let baseAlertCss = 'ontario-alert ontario-alert--';

    switch (this.alertType) {
      case 'informational':
      case 'information':
        baseAlertCss += 'informational';
        this.alertIcon = '#ontario-icon-alert-information';
        break;
      case 'warning':
      case 'sessionWarning':
      case 'cancel':
        baseAlertCss += 'warning';
        this.alertIcon = '#ontario-icon-alert-warning';
        break;
      case 'error':
      case 'apiError':
      case 'authError':
        baseAlertCss += 'error';
        this.alertIcon = '#ontario-icon-alert-error';
        break;
      case 'success':
        baseAlertCss += 'success';
        this.alertIcon = '#ontario-icon-alert-success';
        break;
      default:
        console.log('alert type does not exist');
    }

    return baseAlertCss;
  }

  cancelComplaint(value: boolean) {
    this.cancelEvent.emit(value);
  }

  extendSession(value: boolean) {
    this.sessionEvent.emit(value);
  }

  navigateToValidation(error: string) {
    this.scrollToValidation.emit(error);
  }

  onRowKeyDown(event: KeyboardEvent, error: any) {
    if (event.key === 'Enter' || event.key === ' ') {
      this.navigateToValidation(error);
      event.preventDefault();
    }
  }

}
