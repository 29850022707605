<div class="ontario-row" tabindex="0" id="{{alertType}}">
    <div class="ontario-alert ontario-alert" [ngClass]="setupAlert()">
        <div class="ontario-alert__header">
            <div class="ontario-alert__header-icon">
                <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary"
                    viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use [attr.href]="this.alertIcon"></use>
                </svg>
            </div>
            <h2 class="ontario-alert__header-title ontario-h4">{{ this.alertTitle |
                translate }}</h2>
        </div>
        <div class="ontario-alert__body">
            <div *ngFor="let message of alertMessage">
                <p>{{ message | translate }}
                    <span *ngIf="message==='tsoa-cfs-public.endPage.reference'"> {{ referenceId }} </span>
                </p>
            </div>
            <ul *ngFor="let error of alertErrorList">
                <li><a id="errorDetail" (click)="navigateToValidation(error)" tabindex="0"
                        (keydown)="onRowKeyDown($event, error)">{{
                        (needsTranslate ? (isPublic ? 'tsoa-cfs-public.alerts.error.' : 'tsoa-cfs-admin.common.alerts.')
                        + error : error) |
                        translate}} </a></li>
            </ul>
            <div *ngIf="alertType==='cancel'">
                <div class='ontario-row'>
                    <div class='ontario-columns ontario-small-12 ontario-medium-12 ontario-large-8'
                        style="margin-top:1rem">
                        <button id="yesButton" tabindex="0" class="ontario-button ontario-button--primary" type="button"
                            (click)="cancelComplaint(true)">
                            {{'common.yes' | translate }}
                        </button>
                        <button id="noButton" tabindex="0" class="ontario-button ontario-button--secondary"
                            style="margin-top: 20px;" type="secondary" (click)="cancelComplaint(false)">
                            {{'common.no' | translate }}
                        </button>
                    </div>
                </div>
            </div>
            <div *ngIf="alertType==='sessionWarning'">
                <div class='ontario-row'>
                    <div class='ontario-columns ontario-small-12 ontario-medium-12 ontario-large-8'>
                        <button class="ontario-button ontario-button--primary" type="button"
                            (click)="extendSession(true)" id="yesButton">
                            {{'common.extendSession' | translate }}
                        </button>
                        <button class="ontario-button ontario-button--secondary" style="margin-top: 20px;"
                            type="secondary" (click)="extendSession(false)" id="noButton">
                            {{'common.endSession' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>