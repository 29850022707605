import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { scrollToValidation, IncidentInformation, Vehicle, Errors, fromISO, maxDateValidator, minDateValidator, toISO, scrollToTop, filterFromArray, checkInvalidAndRemoveFromErrors, checkInvalidAndRemoveFromErrorsFormGroupArray, getFormValidationErrors, scrollToAlertPanel } from 'projects/common-lib/src/public-api';
import { Observable, Subscription } from 'rxjs';
import { StateManagerService } from '../../../services/state-manager.service';

@Component({
  selector: 'app-incident-info-page2',
  templateUrl: './incident-info-page2.component.html',
  styleUrls: ['./incident-info-page2.component.scss'],
})
export class IncidentInfoPage2Component implements OnInit, OnDestroy {
  maxDate = new Date();
  minDate = new Date(2024, 0, 1);

  private eventsSubscription!: Subscription;
  inputForm!: FormGroup;
  vinPattern = '^[A-Za-z0-9]{2,17}$';

  information: IncidentInformation = {
    vehicles: [],
    exactDateTime: '', //TODO: Change?
    locationOfIncident: '',
  };

  vehicleArray: Vehicle[] = [];
  vehicleCount = 0; // 0-indexed, #vehicles = vehicleCount + 1

  @Input() events!: Observable<number>;
  @Output() formValidityChanged = new EventEmitter<Errors>();
  listOfErrors: string[] = [];

  scrollToValidation = scrollToValidation;

  constructor(
    private stateManagerService: StateManagerService,
    private titleService: Title,
    private router: Router
  ) {
    if (this.router.url.includes('fr/tssea')) {
      this.titleService.setTitle("Date et heure de l'incident | BSREV"); // French tab title
    }
    else {
      this.titleService.setTitle('Incident date and time | TVSO'); // English tab title
    }
  }

  ngOnInit(): void {
    this.information = this.stateManagerService.getInformation();
    //Convert date back from ISO
    if (this.information.exactDateTime.length > 0)
      this.information.exactDateTime = fromISO(this.information.exactDateTime);
    this.vehicleArray = this.stateManagerService.getInformation().vehicles;
    this.eventsSubscription = this.events.subscribe((stepperStep) => {
      if (stepperStep === 2) {
        this.verify();
      }
      this.saveState();
    });
    this.initForm();
  }

  initForm(): void {
    this.inputForm = new FormGroup({
      // either the date coming in or null if no date set yet
      dateTimeInput: new FormControl(this.information.exactDateTime, [
        Validators.required,
        maxDateValidator(this.maxDate),
        minDateValidator(this.minDate)
      ]),
      locationInput: new FormControl(this.information.locationOfIncident, [Validators.maxLength(100)]),
      vehicleFormArray: new FormArray([
        // First (default) Entry
        new FormGroup({
          plateNumInput: new FormControl(this.vehicleArray.length > 0 ? this.vehicleArray[0].plateNum : '', [
            Validators.pattern('[A-Za-z0-9 -]{2,20}'),
          ]),
          vinInput: new FormControl(this.vehicleArray.length > 0 ? this.vehicleArray[0].vinNum : '', [
            Validators.pattern(this.vinPattern),
            Validators.maxLength(17),
          ]),
        }),
      ]),
    });

    // Add existing vehicles to form array
    this.vehicleArray.slice(1).forEach((vehicle) => {
      this.vehicleFormArray.controls.push(
        new FormGroup({
          plateNumInput: new FormControl(vehicle.plateNum, [Validators.pattern('[A-Za-z0-9 -]{2,20}')]),
          vinInput: new FormControl(vehicle.vinNum, [Validators.pattern(this.vinPattern)]),
        }),
      );
    });

    if (this.vehicleArray.length > 0) {
      this.vehicleCount = this.vehicleArray.length - 1;
    }
  }

  get dateTimeInput(): any {
    return this.inputForm.get('dateTimeInput');
  }

  get locationInput(): any {
    return this.inputForm.get('locationInput');
  }

  get vehicleFormArray() {
    return this.inputForm.get('vehicleFormArray') as FormArray;
  }

  getVinInput(index: number): AbstractControl | null {
    const vehicleFormGroup = this.vehicleFormArray.at(index) as FormGroup;
    return vehicleFormGroup ? vehicleFormGroup.get('vinInput') : null;
  }

  getPlateInput(index: number): AbstractControl | null {
    const vehicleFormGroup = this.vehicleFormArray.at(index) as FormGroup;
    return vehicleFormGroup ? vehicleFormGroup.get('plateNumInput') : null;
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  saveState() {

    const datetimeInput = this.dateTimeInput.value;

    if (datetimeInput !== "")
      this.information.exactDateTime = toISO(datetimeInput);

    this.information.locationOfIncident = this.locationInput.value;

    // Form array info (plate #'s & vins)
    for (let i = 0; i < this.vehicleFormArray.length; i++) {
      const vehicleFormGroup = this.vehicleFormArray.at(i) as FormGroup;
      const plateNumControl = vehicleFormGroup.get('plateNumInput') as FormControl;
      const vinControl = vehicleFormGroup.get('vinInput') as FormControl;

      //TODO: check if the vehicle has an empty plate num and/or empty vin before adding to array
      this.information.vehicles[i] = {
        plateNum: plateNumControl.value,
        vinNum: vinControl.value,
      };
    }
    // update
    this.stateManagerService.updateInformation(this.information);
  }

  addVehicle() {
    this.vehicleFormArray.push(
      new FormGroup({
        plateNumInput: new FormControl('', [Validators.pattern('[A-Za-z0-9 -]{2,20}')]),
        vinInput: new FormControl('', [Validators.pattern(this.vinPattern)]),
      }),
    );
    this.vehicleCount++;
    this.vehicleCount === 9 ? scrollToTop() : null; // Scroll to alert if max # of vehicles reached
  }

  deleteVehicle(indexToRemove: number) {
    this.vehicleFormArray.removeAt(indexToRemove);
    this.vehicleCount--;
    this.information.vehicles.splice(indexToRemove, 1);
    this.stateManagerService.updateInformation(this.information);

    filterFromArray(this.vehicleFormArray, 'vinInput', this.listOfErrors);
    filterFromArray(this.vehicleFormArray, 'plateNumInput', this.listOfErrors);
  }

  checkInvalid(control: string): void {
    checkInvalidAndRemoveFromErrors(this.inputForm, control, this.listOfErrors);
    if (control === 'dateTimeInput') {
      checkInvalidAndRemoveFromErrors(this.inputForm, control, this.listOfErrors); // check again for date time because it has 2 validators
    }
  }

  checkInvalidArray(index: number, control: string) {
    checkInvalidAndRemoveFromErrorsFormGroupArray(this.vehicleFormArray, index, control, this.listOfErrors);
  }

  verify() {
    this.inputForm.markAllAsTouched();
    if (this.inputForm.invalid) {
      getFormValidationErrors(this.inputForm, this.listOfErrors);
      scrollToAlertPanel();
    }
    this.formValidityChanged.emit({ validity: this.inputForm.valid, listOfErrors: this.listOfErrors });
  }
}
