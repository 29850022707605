import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'projects/cfs-public/src/environments/environment';
import { ContactInfo, checkInvalidAndRemoveFromErrors, getFormValidationErrors, scrollToTop, PublicRouteEnum, scrollToValidation, CommonLibService, scrollToAlertPanel } from 'projects/common-lib/src/public-api';
import { StateManagerService } from '../../services/state-manager.service';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
})
export class ContactInfoComponent implements OnInit {
  contactInfo!: ContactInfo;
  organizationOptions!: string[];
  inputForm!: FormGroup;
  orgDetected!: string | undefined;
  hasErrors = false;
  listOfErrors: string[] = [];
  cancelClicked = false;
  scrollToValidation = scrollToValidation;

  constructor(
    private stateManagerService: StateManagerService,
    private commonLibService: CommonLibService,
    private titleService: Title,
    private router: Router,
  ) {
    if (this.router.url.includes('fr/tssea')) {
      this.titleService.setTitle('Page de destination | BSREV'); // French tab title
    }
    else {
      this.titleService.setTitle('Contact information | TVSO'); // English tab title
    }
  }

  ngOnInit(): void {
    this.contactInfo = this.stateManagerService.getContactInfo();
    this.organizationOptions = this.stateManagerService.getPartnerOrganizationsFromPartnerDomainJSON();
    this.organizationOptions.push('NOT_APPLICABLE');
    this.initForm();
  }

  initForm(): void {
    this.inputForm = new FormGroup({
      fullNameInput: new FormControl(this.contactInfo.fullName, [Validators.maxLength(100), Validators.required, Validators.pattern('^[A-Z a-z]{1,100}$')]),
      phoneNumberInput: new FormControl(this.contactInfo.phoneNumber),
      emailInput: new FormControl(this.contactInfo.emailAddress, [
        Validators.pattern('^[\\w-\\.]+@([a-zA-Z-]+\\.)+[a-zA-Z-]{2,3}$'),
        Validators.required,
      ]),
      organizationInput: new FormControl(this.contactInfo.organizationName),
    });
  }

  get fullNameInput(): any {
    return this.inputForm.get('fullNameInput');
  }

  get phoneNumberInput(): any {
    return this.inputForm.get('phoneNumberInput');
  }

  get emailInput(): any {
    return this.inputForm.get('emailInput');
  }

  get organizationInput(): any {
    return this.inputForm.get('organizationInput');
  }

  updateSelected(e: EventTarget | null) {
    if (e === null) return;

    this.contactInfo.organizationName = (e as HTMLInputElement).value;
  }

  lookUpDomain(event: any) {
    if (event === null) return;

    // only do look up if we have '@' and '.' in email field
    // TODO: maybe only look up if email.valid -> but need to change pattern validation
    if (event.target.value.includes('@') && event.target.value.includes('.')) {
      // substring to get domain
      const domain = event.target.value.substring(event.target.value.indexOf('@') + 1);

      // remove whitespace if any and make lowercase
      domain.replace(/\s/g, '');

      // get org associated with domain
      this.orgDetected = this.stateManagerService.getPartnerOrganizationFromDomain(domain);

      if (this.orgDetected !== undefined) {
        this.organizationInput.setValue(this.orgDetected);
        this.organizationInput.patchValue(this.orgDetected);
      }
    }
  }

  saveState() {
    // populate data in contactInfo object

    this.contactInfo.fullName = this.fullNameInput.value;
    this.contactInfo.phoneNumber = this.phoneNumberInput.value;
    this.contactInfo.emailAddress = this.emailInput.value;
    this.contactInfo.organizationName = this.organizationInput.value;

    // check if partner
    if (this.contactInfo.organizationName !== 'NOT_APPLICABLE') {
      this.contactInfo.organizationType = 'partner';
    } else {
      this.contactInfo.organizationType = 'public';
    }

    this.stateManagerService.updateContactInfo(this.contactInfo);
  }

  checkInvalid(control: string): void {
    checkInvalidAndRemoveFromErrors(this.inputForm, control, this.listOfErrors);
  }

  isInputValid(): boolean {
    if (!this.inputForm.valid) {
      // show errors
      this.inputForm.markAllAsTouched();
      this.hasErrors = true;
      getFormValidationErrors(this.inputForm, this.listOfErrors);

      scrollToAlertPanel();
      return false;
    }
    this.hasErrors = false;
    return true;
  }

  goBack() {
    this.cancelClicked = false;
    this.saveState();
    this.stateManagerService.setRouteStep(PublicRouteEnum.LANDING_PAGE);
    this.router.navigate([this.commonLibService.getCurrentLanguage() + `/${environment.rootRoute}/`]);
  }

  goNext() {
    this.cancelClicked = false;
    this.saveState();
    if (this.isInputValid()) {
      this.stateManagerService.setRouteStep(PublicRouteEnum.ENTER_INCIDENT_INFO);
      this.router.navigate([this.commonLibService.getCurrentLanguage() + '/' + `${environment.rootRoute}` + '/', 'enter-incident-info']);
    }
  }

  goCancel() {
    scrollToAlertPanel();


    this.cancelClicked = true;
  }

  cancel(input: boolean) {
    if (input === true) {
      // delete all entered data
      this.stateManagerService.clearStateObject();

      this.stateManagerService.setRouteStep(PublicRouteEnum.LANDING_PAGE);

      // then go to home page
      this.router.navigate([this.commonLibService.getCurrentLanguage() + `/${environment.rootRoute}/`]);
      scrollToTop();
    } else {
      // else hide the alert
      this.cancelClicked = false;
    }
  }

}
