<div class="ontario-row">
    <div class='ontario-columns ontario-small-12 ontario-medium-12 ontario-large-12'>

        <lib-loading-indicator *ngIf="showLoadingIndicator" [fullScreen]="true"></lib-loading-indicator>

        <lib-alert-panel *ngIf="downloadError" [alertType]="'error'"
            [alertTitle]="'common.alerts.downloadComplaintError.title'"
            [alertMessage]="['common.alerts.downloadComplaintError.sub-title']"></lib-alert-panel>

        <lib-alert-panel *ngIf="showSuccess" [alertType]="'success'" [alertTitle]="'tsoa-cfs-public.endPage.success'"
            [alertMessage]="['tsoa-cfs-public.endPage.submitted', 'tsoa-cfs-public.endPage.reference']"
            [referenceId]="referenceId"></lib-alert-panel>

        <h2 class="ontario-h1">{{ 'tsoa-cfs-public.endPage.title' | translate }}</h2>

        <div>
            <ol>
                <li>{{ 'tsoa-cfs-public.endPage.step1' | translate }}</li>
                <li>{{ 'tsoa-cfs-public.endPage.step2' | translate }}</li>
                <li>{{ 'tsoa-cfs-public.endPage.step3' | translate }}</li>
            </ol>
            <p>{{ 'tsoa-cfs-public.endPage.notice' | translate }} </p>
        </div>

        <button class="ontario-button ontario-button--primary" type="primary" (click)="downloadComplaint()">{{
            'tsoa-cfs-public.endPage.download' | translate }}</button>

    </div>
</div>