import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { Message } from '@stomp/stompjs';
import { Router } from '@angular/router';
import { HttpEventType } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';
import { environment } from 'projects/cfs-public/src/environments/environment';
import { Errors, IncidentSummary, ErrorsEnum, DocumentStatus, FileWidget, getFormValidationErrors, scrollToAlertPanel, checkInvalidAndRemoveFromErrors, TsoaComplaintGetOneResult, PublicRouteEnum, CommonLibService } from 'projects/common-lib/src/public-api';
import { ComplaintService } from '../../../services/complaint.service';
import { StateManagerService } from '../../../services/state-manager.service';
import { AttachementService } from '../../../services/attachement.service';
import { RxStompService } from '../../../services/rx-stomp.service';

@Component({
  selector: 'app-incident-info-page4',
  templateUrl: './incident-info-page4.component.html',
  styleUrls: ['./incident-info-page4.component.scss'],
})
export class IncidentInfoPage4Component implements OnInit, OnDestroy {
  private eventsSubscription!: Subscription;
  private topicSubscription!: Subscription;
  private attachementSubscription!: Subscription;
  private submitSubscription!: Subscription;

  @Input() events!: Observable<number>;
  @Output() formValidityChanged = new EventEmitter<Errors>();
  @Output() uploadBatchSuccess = new EventEmitter<boolean>();
  @Output() maxFileWarning = new EventEmitter<boolean>();

  sessionUUID: string;
  listOfErrors: string[] = [];
  inputForm!: FormGroup;
  isCollapsed = true;
  showLoadingIndicator = false;
  display = 'none';
  spanClass = 'ontario-accordion__button-icon--open';
  chevron = '#ontario-icon-chevron-down';
  acceptedExt = [".pdf", ".doc", ".docx", ".bmp", ".png", ".jpg", ".jpeg", ".txt", ".md", ".xls", ".xlsx", ".mpeg", ".mov", ".mp3", ".mp4", ".avi", ".wmv", ".ogg", ".webm"];
  uploadFileSuccess!: boolean;

  complaintSubmitted = false;

  incidentSummary: IncidentSummary = {
    summaryOfIncident: '',
    documents: [],
    clientReferenceNum: '',
  };

  apiError = ErrorsEnum.API_ERROR;
  fileError = ErrorsEnum.FILE_ERROR;
  sizeError = ErrorsEnum.SIZE_ERROR;
  authError = ErrorsEnum.AUTH_ERROR;

  scrollToValidation = (error: string): void => {
    let id;
    if (error !== 'Server returned an error' && error !== 'File size too large') {
      id = error.split('.')[0];
    }
    else {
      id = 'listOfUploaded';
    }
    const elementToScrollTo = document.getElementById(id);
    elementToScrollTo?.focus();
  };

  constructor(
    private complaintService: ComplaintService,
    private stateManagerService: StateManagerService,
    private commonLibService: CommonLibService,
    private titleService: Title,
    private attachementService: AttachementService,
    private rxStompService: RxStompService,
    private router: Router,
  ) {
    if (this.router.url.includes('fr/tssea')) {
      this.titleService.setTitle("Sommaire et téléversement | BSREV"); // French tab title
    }
    else {
      this.titleService.setTitle("Summary and upload | TVSO"); // English tab title
    }

    this.sessionUUID = stateManagerService.getSessionUUID();
    // match page to application state 
    this.incidentSummary = this.stateManagerService.getSummary();
  }

  ngOnInit(): void {
    // Subscibe to websocket on the topic of the session that will send messages for each uploaded file in part
    this.topicSubscription = this.rxStompService.watch(`/topic/events/${this.sessionUUID}`)
      .subscribe((message: Message) => {
        const documentStatus: DocumentStatus = JSON.parse(message.body);
        let index = this.incidentSummary.documents.findIndex((file) => file.UUID === documentStatus.documentUuid);
        if (index !== -1 && documentStatus.progress !== null && (documentStatus.progress < 75 || documentStatus.status === "Created")) {
          this.incidentSummary.documents[index] = { ...this.incidentSummary.documents[index], progress: 100 + documentStatus.progress };
        }

        if (documentStatus.status === "Created") {
          this.uploadBatchSuccess.emit(true);
          this.fileUploadedComplete(true);
          this.incidentSummary.documents[index].successStatus = true;
          if (this.incidentSummary.documents.every(file => file.successStatus === true) && this.showLoadingIndicator && this.complaintSubmitted === false && this.listOfErrors.length === 0) {
            this.complaintSubmitted = true;
            this.submitComplaint();
          }
        }
      });

    this.eventsSubscription = this.events.subscribe((stepperStep) => {
      this.saveState();
      if (stepperStep === 4) {
        // before we validate remove success alert if present
        this.uploadBatchSuccess.emit(false);
        // check if everything is valid
        this.verify();
        if (this.everythingValid()) {
          // if it is valid submit the complaint
          this.showLoadingIndicator = true;
          if (this.incidentSummary.documents.every(file => file.successStatus === true) && this.complaintSubmitted === false) {
            this.complaintSubmitted = true;
            this.submitComplaint();
          }
        }
      }
    });

    // Initialize the form with whatever the saved state for the page is or empty
    this.initForm();

  }

  initForm(): void {
    this.inputForm = new FormGroup({
      summaryOfIncidentInput: new FormControl(this.incidentSummary.summaryOfIncident, [
        Validators.required,
        Validators.maxLength(5000),
      ]),
      referenceNumberInput: new FormControl(this.incidentSummary.clientReferenceNum, [Validators.maxLength(100)]),
    });
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
    this.topicSubscription.unsubscribe();
    if (this.attachementSubscription)
      this.attachementSubscription.unsubscribe();
    if (this.submitSubscription)
      this.submitSubscription.unsubscribe();
  }

  get summaryOfIncidentInput() {
    return this.inputForm.get('summaryOfIncidentInput');
  }

  get referenceNumberInput() {
    return this.inputForm.get('referenceNumberInput');
  }

  saveState() {
    this.incidentSummary.summaryOfIncident = this.summaryOfIncidentInput?.value;
    this.incidentSummary.clientReferenceNum = this.referenceNumberInput?.value;
    this.stateManagerService.updateSummary(this.incidentSummary);
  }

  toggle() {
    this.isCollapsed = !this.isCollapsed;
    if (this.isCollapsed) {
      this.display = 'none';
      this.spanClass = 'ontario-accordion__button-icon--open';
      this.chevron = '#ontario-icon-chevron-down';
    } else {
      this.display = 'block';
      this.spanClass = 'ontario-accordion__button-icon--close';
      this.chevron = '#ontario-icon-chevron-up';
    }
  }

  isFileError(): boolean { return this.incidentSummary.documents.some((file: FileWidget) => file.errorMessage === this.fileError); }
  isSizeError(): boolean { return this.incidentSummary.documents.some((file: FileWidget) => file.errorMessage === this.sizeError); }
  isAuthError(): boolean { return this.incidentSummary.documents.some((file: FileWidget) => file.errorMessage === this.authError); }
  everythingValid(): boolean { return this.inputForm.valid && !this.isFileError() && !this.isSizeError() && !this.isAuthError() && this.listOfErrors.length === 0; }

  verify() {
    this.inputForm.markAllAsTouched();
    if (this.inputForm.invalid) {
      getFormValidationErrors(this.inputForm, this.listOfErrors);
      scrollToAlertPanel();
    }
    this.formValidityChanged.emit({ validity: this.inputForm.valid && !this.isFileError() && !this.isSizeError() && !this.isAuthError(), listOfErrors: this.listOfErrors });
  }

  removeFileError(typeOfError: string) {
    const err = this.listOfErrors.find((x) => { return x.includes(typeOfError); });

    if (err !== undefined) {
      this.listOfErrors.splice(this.listOfErrors.indexOf(err), 1);
    }
  }

  checkInvalid(control: string): void {
    checkInvalidAndRemoveFromErrors(this.inputForm, control, this.listOfErrors);
  }

  submitComplaint() {
    this.submitSubscription = this.complaintService
      .submitComplaintWithREST(this.stateManagerService.getStateObject())
      .subscribe({
        next: (result: TsoaComplaintGetOneResult) => {
          this.showLoadingIndicator = false;

          // save reference id
          if (result.tsoaComplaint.mtoReferenceId) {
            this.stateManagerService.updateMtoReferenceId(result.tsoaComplaint.mtoReferenceId);
            // route to next page
            this.uploadFileSuccess = false;
            this.stateManagerService.setRouteStep(PublicRouteEnum.SUBMIT_COMPLAINT);
            this.router.navigate([this.commonLibService.getCurrentLanguage() + '/' + `${environment.rootRoute}` + '/', 'submit-complaint']);
            scrollToAlertPanel();
          }

        },
        error: (error) => {
          this.showLoadingIndicator = false;
          this.complaintSubmitted = false;

          // show error
          console.error(error);
          this.listOfErrors.push(this.apiError);

          this.formValidityChanged.emit({ validity: false, listOfErrors: this.listOfErrors });
        },
      });
  }

  filesUpload(e: Event | null) {
    if (e === null || e?.target === null || (e.target as HTMLInputElement).files === null) return;
    const uploadedFiles: FileList | null = (e.target as HTMLInputElement).files;
    if (uploadedFiles !== null)
      Array.from(uploadedFiles).forEach(file => {
        const fileExt = file.name.split('.').pop() ?? "";
        const documentUUID = uuidv4();
        const fileToUpload: FileWidget = { fileName: file.name, fileSize: file.size, UUID: documentUUID, progress: 0 };

        // Checking size of the fileWidget array (max 50)
        if (this.incidentSummary.documents.length >= 50) {
          // Emit warning
          this.maxFileWarning.emit(true);
        }
        else {
          const initialIndex = this.incidentSummary.documents.push(fileToUpload) - 1;
          this.maxFileWarning.emit(false);
          // Checking size, skipping post for those that are too large
          if (file.size > 250000000) {
            this.incidentSummary.documents[initialIndex] = { ...this.incidentSummary.documents[initialIndex], error: true, errorMessage: this.sizeError };
            if (this.isSizeError() && !this.listOfErrors.includes(ErrorsEnum.SIZE_ERROR)) {
              this.listOfErrors.push(this.sizeError);

            }
            this.uploadBatchSuccess.emit(false);
            this.formValidityChanged.emit({ validity: false, listOfErrors: this.listOfErrors });
            this.fileUploadedComplete(false);

            return;
          }

          if (!this.acceptedExt.includes("." + fileExt.toLowerCase())) {

            this.incidentSummary.documents[initialIndex] = ({ ...this.incidentSummary.documents[initialIndex], error: true, errorMessage: this.fileError });
            if (this.isFileError() && !this.listOfErrors.includes(ErrorsEnum.FILE_ERROR)) {
              this.listOfErrors.push(this.fileError);

            }
            this.uploadBatchSuccess.emit(false);
            this.formValidityChanged.emit({ validity: false, listOfErrors: this.listOfErrors });
            this.fileUploadedComplete(false);

            return;
          }

          // Gotta reconsider hwo you do this if you want progress
          // Likely gonna have to push to the thing before you realize whether or not it is good or not (do we even use UUID?)

          this.attachementSubscription = this.attachementService.postAttachement(file, this.sessionUUID, fileToUpload.UUID).subscribe({
            next: (event) => {
              switch (event.type) {
                case HttpEventType.UploadProgress: {
                  // This checks to see that the document we are interested in still exists in the array on the UI
                  const index = this.incidentSummary.documents.findIndex((file) => file.UUID === fileToUpload.UUID);
                  if (index !== -1 && event.loaded > 0) {
                    this.incidentSummary.documents[index] = { ...this.incidentSummary.documents[index], progress: (event.loaded / this.incidentSummary.documents[index].fileSize) * 100 };
                  }
                  break;
                }
                case HttpEventType.Response:
                  break;
              }
            },
            error: (error) => {
              // This checks to see that the document we are interested in still exists in the array on the UI
              const index = this.incidentSummary.documents.findIndex((file) => file.UUID === fileToUpload.UUID);
              if (index != -1) {
                this.incidentSummary.documents[index] = ({ ...this.incidentSummary.documents[index], error: true, errorMessage: this.fileError, progress: 0 });

                console.error(error);
                // This needs to add an error so that the success alert doesnt pop
                if (this.isFileError() && !this.listOfErrors.includes(ErrorsEnum.FILE_ERROR)) {
                  this.listOfErrors.push(this.fileError);
                } else if (this.isAuthError() && !this.listOfErrors.includes(ErrorsEnum.AUTH_ERROR)) {
                  this.listOfErrors.push(this.fileError);
                }
                this.uploadBatchSuccess.emit(false);
                this.formValidityChanged.emit({ validity: false, listOfErrors: this.listOfErrors });
                this.fileUploadedComplete(false);
              }
            }
          });
        }
      });
  }

  fileUploadedComplete(state: boolean) {
    this.uploadFileSuccess = state;
    scrollToAlertPanel(state);
  }

  deleteFromUploaded(UUID: string) {

    if (this.incidentSummary.documents.length === 50) {
      // Stop emiting warning
      this.maxFileWarning.emit(false);
    }

    const index = this.incidentSummary.documents.findIndex((file: FileWidget) => file.UUID === UUID);
    if (index != -1) {
      this.incidentSummary.documents.splice(index, 1);
    }

    if (!this.isFileError()) {
      this.removeFileError(this.fileError);
    }
    if (!this.isSizeError()) {
      this.removeFileError(this.sizeError);
    }

  }
}
