<div class="ontario-row">
    <div *ngIf="vehicleCount===9" class="ontario-alert ontario-alert--warning">
        <div class="ontario-alert__header">
            <div class="ontario-alert__header-icon">
                <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary"
                    viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                    <use href="#ontario-icon-alert-warning"></use>
                </svg>
            </div>
            <h2 class="ontario-alert__header-title ontario-h4">{{ 'tsoa-cfs-public.secondFormsPage.Max vehicle alert' |
                translate }}</h2>
        </div>
        <div class="ontario-alert__body">
            <p>{{ 'tsoa-cfs-public.secondFormsPage.Max vehicle alert info' | translate }}</p>
        </div>
    </div>
    <div class='ontario-columns ontario-small-12 ontario-medium-12 ontario-large-12'>

        <h2 class="ontario-h1">{{ 'tsoa-cfs-public.secondFormsPage.title' | translate }}</h2>
        <p><br></p>
        <form [formGroup]="inputForm">
            <div>
                <label for="dateTimeInput">
                    <h2>{{ 'tsoa-cfs-public.secondFormsPage.Date and time' | translate }}</h2>
                </label>
                <p style="margin-bottom: 0;"> {{ 'tsoa-cfs-public.secondFormsPage.Date after January 2024' | translate
                    }} </p>
                <p>{{ 'tsoa-cfs-public.secondFormsPage.Select date and time' | translate
                    }}<span class=" ontario-label__flag">({{ 'common.required' | translate }})</span></p>
                <lib-inline-validaton *ngIf="dateTimeInput.touched && dateTimeInput.errors"
                    [fieldName]="'dateTimeInput'" id="validation.dateTimeInput.required"
                    [message]=" dateTimeInput.errors?.maxDate ? 'tsoa-cfs-public.secondFormsPage.Date and time max' : 'tsoa-cfs-public.secondFormsPage.Date and time required'"></lib-inline-validaton>
                <!-- CALENDAR -->
                <input class="ontario-input" type="datetime-local" id="dateTimeInput"
                    [ngClass]="{'ontario-input__error': dateTimeInput.touched && dateTimeInput.errors }"
                    formControlName="dateTimeInput" (change)="checkInvalid('dateTimeInput')"
                    [max]="maxDate | date:'YYYY-MM-ddThh:mm'" [min]="minDate | date:'YYYY-MM-ddThh:mm'" />
            </div>

            <div>
                <h2>{{ 'tsoa-cfs-public.secondFormsPage.Location of incident' | translate }}</h2>
                <p>{{ 'tsoa-cfs-public.secondFormsPage.Location of incident example' | translate }}</p>
                <label for="incidentPage2-location">
                    <h3>{{ 'tsoa-cfs-public.secondFormsPage.Location' | translate }}</h3>
                </label>
                <input class=" ontario-input" type="text" id="incidentPage2-location" formControlName="locationInput"
                    maxlength="200" />
            </div>

            <div>
                <h2>{{ 'tsoa-cfs-public.secondFormsPage.Information about vehicle' | translate }}</h2>
                <p>{{ 'tsoa-cfs-public.secondFormsPage.Enter all information' | translate }}</p>

                <div formArrayName="vehicleFormArray">
                    <div *ngFor="let vehicle of vehicleFormArray.controls; let i = index">
                        <div class="vehicle-header-container">
                            <h2 *ngIf="vehicleCount>0" class="vehicle-header">{{
                                'tsoa-cfs-public.secondFormsPage.Vehicle' | translate }} {{ i
                                + 1
                                }}
                            </h2>
                            <button *ngIf="i>0" class="ontario-button ontario-button--tertiary delete-button"
                                (click)="deleteVehicle(i)">
                                {{ 'tsoa-cfs-public.secondFormsPage.Delete vehicle' | translate }}
                            </button>
                        </div>


                        <form [formGroupName]="i">
                            <label for="plateNumInput+{{i}}">
                                <h3>{{ 'tsoa-cfs-public.secondFormsPage.Plate number' | translate }}</h3>
                            </label>
                            <lib-inline-validaton *ngIf="getPlateInput(i)?.touched && getPlateInput(i)?.invalid"
                                [fieldName]="'plateNumInput'" id="validation.plateNumInput.pattern"
                                [message]="'tsoa-cfs-public.secondFormsPage.Invalid Plate'"></lib-inline-validaton>
                            <input class="ontario-input" type="text" id="plateNumInput+{{i}}"
                                formControlName="plateNumInput" maxlength="20"
                                (change)="checkInvalidArray(i, 'plateNumInput')" />
                            <label for="vinInput+{{i}}">
                                <h3>{{ 'tsoa-cfs-public.secondFormsPage.VIN' | translate }}</h3>
                            </label>
                            <lib-inline-validaton *ngIf="getVinInput(i)?.touched && getVinInput(i)?.invalid"
                                [fieldName]="'vinInput'" id="validation.vinInput.pattern"
                                [message]="'tsoa-cfs-public.secondFormsPage.Invalid VIN'"></lib-inline-validaton>
                            <input class="ontario-input" type="text" id="vinInput+{{i}}" [ngClass]=" {'ontario-input__error': getVinInput(i)?.touched && getVinInput(i)?.invalid
                                }" formControlName="vinInput" (change)="checkInvalidArray(i, 'vinInput')"
                                maxlength="17" />
                        </form>
                    </div>
                </div>
            </div>
        </form>

        <button *ngIf="vehicleCount<9" class="ontario-button ontario-button--secondary" (click)="addVehicle()">{{
            'tsoa-cfs-public.secondFormsPage.Add vehicle' | translate }}</button>
    </div>
</div>