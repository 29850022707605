import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export function requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    if (formGroup instanceof FormGroup) {
      let checked = 0;
      Object.keys(formGroup.controls).forEach((key) => {
        const control = formGroup.controls[key];

        if (control.value === true) {
          checked++;
        }
      });

      if (checked < minRequired) {
        return {
          requireCheckboxesToBeChecked: true,
        };
      }

      return null;
    }
    return null;
  };
}

export function requireCheckboxesToBeCheckedArray(): ValidatorFn {
  return (formArray: AbstractControl): ValidationErrors | null => {
    if (formArray instanceof FormArray) {
      const atLeastOneChecked = formArray.controls.some((control) => control.value === true);
      return atLeastOneChecked ? null : { requireCheckboxesToBeChecked: true };
    }
    return null;
  };
}

export function maxDateValidator(maxDate: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const selectedDate = new Date(control.value);
    const currentDate = maxDate;

    if (selectedDate > currentDate) {
      return { maxDate: true };
    }

    return null;
  };
}

export function minDateValidator(minDate: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const selectedDate = new Date(control.value);

    if (selectedDate < minDate) {
      return { minDate: true };
    }

    return null;
  };
}

/* ----------------- ALERT FUNCTIONS START ----------------- */

// expects FormGroup and array to hold errors, populates the passed in array with the form errors
export const getFormValidationErrors = (inputForm: FormGroup, errorArray: string[], checkParent: boolean = false): void => {

  if (checkParent) {
    const topControlError: ValidationErrors | null | undefined = inputForm.errors;
    if (topControlError != null) {
      Object.keys(topControlError).forEach((keyError) => {
        const err = keyError;
        if (!errorArray.includes(err)) {
          errorArray.push(err);
        }
      });
    }
  }

  Object.keys(inputForm.controls).forEach((item) => {
    if (inputForm.get(item) instanceof FormGroup || inputForm.get(item) instanceof FormArray) {
      getFormValidationErrors(inputForm.get(item) as FormGroup, errorArray);
    }
    const controlErrors: ValidationErrors | null | undefined = inputForm.get(item)?.errors;
    if (controlErrors != null) {
      Object.keys(controlErrors).forEach((keyError) => {
        const err = item + '.' + keyError;
        if (!errorArray.includes(err)) {
          errorArray.push(err);
        }
      });
    }
  });
};

export const filterFromArray = (inputArray: FormArray, control: string, listOfErrors: string[]) => {
  if (inputArray.controls.every((formGroup) => formGroup.get(control)?.valid)) {
    const err = listOfErrors.find((x) => {
      return x.includes(control);
    });

    if (err !== undefined) {
      listOfErrors.splice(listOfErrors.indexOf(err), 1);
    }
  }
};

export const checkInvalidAndRemoveFromErrorsFormGroupArray = (
  inputArray: FormArray,
  index: number,
  control: string,
  listOfErrors: string[],
): void => {
  const targetControl = inputArray.at(index).get(control);

  // check if the form control is valid
  // or if there is still a control with an error
  if (!targetControl?.valid || inputArray.controls.some((formGroup) => !formGroup.get(control)?.valid)) {
    getFormValidationErrors(inputArray.at(index) as FormGroup, listOfErrors);
  } else {
    // if it's valid then remove it form the alert

    const err = listOfErrors.find((x) => {
      return x.includes(control);
    });

    if (err !== undefined) {
      listOfErrors.splice(listOfErrors.indexOf(err), 1);
    }
  }
};

export const checkInvalidAndRemoveFromErrors = (
  inputForm: FormGroup,
  control: string,
  listOfErrors: string[],
): void => {
  const targetControl = inputForm.get(control);

  const err = listOfErrors.find((x) => {
    return x.includes(control);
  });

  if (err !== undefined) {
    listOfErrors.splice(listOfErrors.indexOf(err), 1);
  }

  // check if the form control is valid
  if (!targetControl?.valid) {
    getFormValidationErrors(inputForm, listOfErrors);
  } else {
    // if it's valid then remove it form the alert

    const err = listOfErrors.find((x) => {
      return x.includes(control);
    });

    if (err !== undefined) {
      listOfErrors.splice(listOfErrors.indexOf(err), 1);
    }
  }
};

export const scrollToValidation = (error: string): void => {
  var id = error.split('.')[0];

  const elementToScrollTo = document.getElementById(id);
  if (elementToScrollTo) {
    elementToScrollTo.focus();
  }
};

export const scrollToAlertPanel = (isSuccess?: boolean) => {
  setTimeout(() => {
    let alertPanel;
    if (isSuccess) {
      alertPanel = document.querySelector('#successPanel');
    }
    else {
      alertPanel = document.querySelector('#alertPanel');
    }

    if (alertPanel) {
      (alertPanel.firstChild as HTMLElement).focus();
    }
  }, 0);
};

/* ----------------- ALERT FUNCTIONS END ----------------- */

// focus on element
export const focusElement = (reference: any, scroll = true): void => {
  if (!reference || !reference?.nativeElement) {
    return;
  }

  const item = reference.nativeElement;

  item.tabIndex = -1;
  item.focus();
  scroll && item.scrollIntoView({ behavior: 'smooth' });
};

// scrolls to top of screen
export const scrollToTop = (): void => {
  window.scrollTo(0, 0);
};

// scrolls to error
export const scrollToError = (hasErrors: boolean, element: any): void => {
  setTimeout(() => {
    if (hasErrors) {
      setTimeout(() => focusElement(element), 200);
    }
  }, 100);
};

export const toISO = (dateString: string): string => {

  const date = new Date(dateString);
  /*
    If the date is invalid the following function will return NaN
    This returns number of minutes difference from sys time zone to UTC
    So later on we use this to calculate offset hours and minutes through
    division and modulus
  */
  const tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? '+' : '-',
    pad = function (num: number): string {
      return (num < 10 ? '0' : '') + num;
    };

  return date.getFullYear() +
    '-' + pad(date.getMonth() + 1) +
    '-' + pad(date.getDate()) +
    'T' + pad(date.getHours()) +
    ':' + pad(date.getMinutes()) +
    ':' + pad(date.getSeconds()) +
    dif + pad(Math.floor(Math.abs(tzo) / 60)) +
    ':' + pad(Math.abs(tzo) % 60);
};

export const fromISO = (ISOdate: string): string => {
  const date = new Date(ISOdate);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

// stolen from hcvdpp - many thanks
export const getDecodedPdfBlobFromBase64 = (document: any): Blob => {
  const byteCharacters = atob(document);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: 'application/pdf' });
};

// download document and name it
export const downloadDocument = (doc: any): void => {
  const file = getDecodedPdfBlobFromBase64(doc);

  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(file);
  a.download = "Towing and Vehicle Storage Complaint";

  document.body.appendChild(a);
  a.click();

  if (document.body != null) {
    document.body.removeChild(a);
  }
};

// accepts string with delimiter, returns translated string with same delimiter.
export const translateStringWithDelimiter = (input: string, translationString: string, delimiter: string, translate: TranslateService): string => {
  let tempArr = input.split(delimiter);
  tempArr = tempArr.filter((i: any) => !i.includes("INPUT_ONLY"));
  tempArr = tempArr.map((i: any) => {
    return translate.instant(translationString + i);
  });
  return tempArr.join(delimiter);
};

// accepts string with delimiter, returns translated string with same delimiter.
export const translateString = (input: string, translationString: string, translate: TranslateService): string => {
  if (input === null || input === undefined) {
    return '';
  }
  return translate.instant(translationString + input);
};
