import { Component, Input } from '@angular/core';
import { Comment } from 'projects/common-lib/src/public-api';

@Component({
  selector: 'lib-mto-comments-container',
  templateUrl: './mto-comments-container.component.html',
  styleUrls: ['./mto-comments-container.component.scss']
})
export class MtoCommentsContainerComponent {

  @Input() comments!: Comment[];
  // for some reason they want to display empty rows of comments when there aren't any comments
  emptyComments: Comment[] =
    [
      {
        userName: "",
        createDate: "",
        comment: "",
      },
      {
        userName: "",
        createDate: "",
        comment: "",
      },
      {
        userName: "",
        createDate: "",
        comment: "",
      },
      {
        userName: "",
        createDate: "",
        comment: "",
      }
    ];
}
