<div class='ontario-row'>
    <div class="body">
        <img src="../../../assets/images/404-error-logo.svg" alt="" class="image">
        <div class="error-text">
            <h1>404 Error | Erreur 404</h1>
            <p>Sorry, but we're unable to find this page. <a
                    href='https://www.ontario.ca/page/get-towing-vehicle-storage-certificate' target="_blank">Go to
                    tow and vehicle storage page | Ontario.ca</a> </p>
            <p>Désolé mais, nous n'arrivons pas à trouver cette page. <a
                    href='https://www.ontario.ca/fr/page/obtenez-certificat-remorquage-dentreposage-vehicules'
                    target="_blank">Aller à | Ontario.ca</a> </p>
        </div>
    </div>
</div>