import { NgModule } from '@angular/core';

/* Modules */
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { CommonLibModule } from 'projects/common-lib/src/public-api';
import { RecaptchaModule } from "ng-recaptcha";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { provideNgxMask, NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { rxStompServiceFactory } from './services/rx-stomp-service-factory';
import { RxStompService } from './services/rx-stomp.service';
import { provideUserIdleConfig } from 'angular-user-idle';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';

/* Components */
import { AppComponent } from './app.component';
import { LandingPageComponent } from './page-components/landing-page/landing-page.component';
import { ContactInfoComponent } from './page-components/contact-info/contact-info.component';
import { IncidentInfoComponent } from './page-components/incident-info/incident-info.component';
import { IncidentInfoPage1Component } from './page-components/incident-info-components/incident-info-page1/incident-info-page1.component';
import { IncidentInfoPage2Component } from './page-components/incident-info-components/incident-info-page2/incident-info-page2.component';
import { IncidentInfoPage3Component } from './page-components/incident-info-components/incident-info-page3/incident-info-page3.component';
import { IncidentInfoPage4Component } from './page-components/incident-info-components/incident-info-page4/incident-info-page4.component';
import { TowCompanyDriverPageComponent } from './page-components/incident-info-components/incident-info-page3/incident-info-page3-components/tow-company-driver-page/tow-company-driver-page.component';
import { VehicleStoragePageComponent } from './page-components/incident-info-components/incident-info-page3/incident-info-page3-components/vehicle-storage-page/vehicle-storage-page.component';
import { EndPageComponent } from './page-components/end-page/end-page.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { environment } from '../environments/environment';



@NgModule({
    declarations: [
        AppComponent,
        StepperComponent,
        LandingPageComponent,
        ContactInfoComponent,
        IncidentInfoComponent,
        IncidentInfoPage1Component,
        IncidentInfoPage2Component,
        IncidentInfoPage3Component,
        IncidentInfoPage4Component,
        TowCompanyDriverPageComponent,
        VehicleStoragePageComponent,
        EndPageComponent
    ],
    providers: [
        provideNgxMask(),
        {
            provide: RxStompService,
            useFactory: rxStompServiceFactory,
        },
        provideUserIdleConfig(environment.sessionWarningConfig),
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CommonLibModule, // adding the common lib module
        RecaptchaModule,
        NgxMaskDirective,
        NgxMaskPipe,
        NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsID),
        NgxGoogleAnalyticsRouterModule,
    ],
    exports: [],
    bootstrap: [AppComponent],
})
export class AppModule { }
