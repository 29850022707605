<footer class='ontario-footer ontario-footer--default'>
    <div class='ontario-row'>
        <div class='ontario-columns ontario-small-12 ontario-medium-7 '>
            <ul class='ontario-footer__links-container ontario-footer__links-container--inline'>
                <li>
                    <a class='ontario-footer__link' (click)="routeToExternalMTOLink('accessability')">{{
                        'common.footer.Accessibility' | translate }}</a>
                </li>
                <li>
                    <a class='ontario-footer__link' (click)="routeToExternalMTOLink('privacy')">{{
                        'common.footer.Privacy' | translate }}</a>
                </li>
                <li>
                    <a class='ontario-footer__link' (click)="routeToExternalMTOLink('contact')">{{
                        'common.footer.Contact us' | translate }}</a>
                </li>
            </ul>
            <div class=' ontario-footer__copyright'>
                <a class='ontario-footer__link' (click)="routeToExternalMTOLink('copyright')">
                    {{ 'common.footer.© King Printer for Ontario, 2024' | translate }} </a>
            </div>
        </div>
    </div>
</footer>