import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StateManagerService } from 'projects/cfs-public/src/app/services/state-manager.service';
import { Errors, VehicleStorageInformation, checkInvalidAndRemoveFromErrors, getFormValidationErrors, scrollToAlertPanel, scrollToTop } from 'projects/common-lib/src/public-api';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-vehicle-storage-page',
  templateUrl: './vehicle-storage-page.component.html',
  styleUrls: ['./vehicle-storage-page.component.scss'],
})
export class VehicleStoragePageComponent implements OnInit, OnDestroy {
  @Input() events!: Observable<number>;
  @Output() formValidityChanged = new EventEmitter<Errors>();
  submitPressed = false;
  inputForm!: FormGroup;
  vehicleStorageInformation: VehicleStorageInformation = {
    storageLocationNum: '',
    addressOfStoredVehicle: '',
    storageCertificateNum: '',
    vehicleStorageCompanyName: '',
  };

  listOfErrors: string[] = [];
  private eventsSubscription!: Subscription;

  constructor(private stateManagerService: StateManagerService) { }

  ngOnInit() {
    this.vehicleStorageInformation = this.stateManagerService.getVehicleStorageInformation();
    this.eventsSubscription = this.events.subscribe((stepperStep) => {
      if (stepperStep === 3) {
        this.verify();
      }
      this.saveState();
    });
    this.initForm();
  }

  initForm(): void {
    this.inputForm = new FormGroup({
      storageLocationNumInput: new FormControl(this.vehicleStorageInformation.storageLocationNum, [
        Validators.maxLength(10),
        Validators.minLength(3),
      ]),
      addressOfStoredVehicleInput: new FormControl(this.vehicleStorageInformation.addressOfStoredVehicle, [
        Validators.maxLength(200),
        Validators.pattern('^(?!https?://|www.|[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z0-9]+|http://).*'),
      ]),
      storageCertificateNumInput: new FormControl(this.vehicleStorageInformation.storageCertificateNum, [
        Validators.maxLength(9),
      ]),
      vehicleStorageCompanyNameInput: new FormControl(this.vehicleStorageInformation.vehicleStorageCompanyName, [
        Validators.maxLength(200),
        Validators.pattern('^(?!https?://|www.|[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z0-9]+|http://).*'),
      ]),
    });
  }

  get storageLocationNumInput(): any {
    return this.inputForm.get('storageLocationNumInput');
  }

  get addressOfStoredVehicleInput(): any {
    return this.inputForm.get('addressOfStoredVehicleInput');
  }

  get storageCertificateNumInput(): any {
    return this.inputForm.get('storageCertificateNumInput');
  }

  get vehicleStorageCompanyNameInput(): any {
    return this.inputForm.get('vehicleStorageCompanyNameInput');
  }

  checkInvalid(control: string): void {
    const beforeRemove = this.listOfErrors.length;
    checkInvalidAndRemoveFromErrors(this.inputForm, control, this.listOfErrors);
    if (this.listOfErrors.length < beforeRemove && this.submitPressed) {
      this.formValidityChanged.emit({
        validity: this.inputForm.valid,
        listOfErrors: this.listOfErrors,
        childName: 'storage',
      });
    }
  }

  pad(controlName: string): void {
    const control = this.inputForm.get(controlName);
    if (control && control.value.length > 2) {
      const paddedValue = control.value.toString().padStart(10, '0');
      control.setValue(paddedValue);
    }
  }

  verify() {
    this.inputForm.markAllAsTouched();
    if (this.inputForm.invalid) {
      getFormValidationErrors(this.inputForm, this.listOfErrors);
      scrollToAlertPanel();
    }
    this.submitPressed = true;
    this.formValidityChanged.emit({
      validity: this.inputForm.valid,
      listOfErrors: this.listOfErrors,
      childName: 'storage',
    });
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  saveState() {
    this.vehicleStorageInformation.storageLocationNum = this.storageLocationNumInput.value;
    this.vehicleStorageInformation.addressOfStoredVehicle = this.addressOfStoredVehicleInput.value;
    this.vehicleStorageInformation.storageCertificateNum = this.storageCertificateNumInput.value;
    this.vehicleStorageInformation.vehicleStorageCompanyName = this.vehicleStorageCompanyNameInput.value;
    this.stateManagerService.updateVehicleStorageInformation(this.vehicleStorageInformation);
  }
}
