import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-inline-validaton',
  templateUrl: './inline-validaton.component.html',
  styleUrls: ['./inline-validaton.component.scss'],
})
export class InlineValidatonComponent {
  @Input() fieldName!: string;

  @Input() message!: string;
}
