import { NgModule } from '@angular/core';

/* Modules */
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* Imports */
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LocalizationModule } from '../translation/localization.module';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';

/* Components */
import { FooterComponent } from '../common-components/footer/footer.component';
import { PageNotFoundComponent } from '../common-components/page-not-found/page-not-found.component';
import { HeaderComponent } from '../common-components/header/header.component';
import { LoadingIndicatorComponent } from '../common-components/loading-indicator/loading-indicator.component';
import { AlertPanelComponent } from '../common-components/validation/alert-panel/alert-panel.component';
import { InlineValidatonComponent } from '../common-components/validation/inline-validaton/inline-validaton.component';
import { FileWidgetComponent } from '../common-components/file-widget/file-widget.component';
import { FileDownloadWidgetComponent } from '../common-components/file-download-widget/file-download-widget.component';
import { MtoCommentsContainerComponent } from '../common-components/mto-comments/mto-comments-container/mto-comments-container.component';
import { MtoCommentComponent } from '../common-components/mto-comments/mto-comment/mto-comment.component';
import { MtoCommentDialogComponent } from '../common-components/mto-comments/mto-comment-dialog/mto-comment-dialog.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/locales/', '.json');
}

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    PageNotFoundComponent,
    LoadingIndicatorComponent,
    AlertPanelComponent,
    InlineValidatonComponent,
    FileWidgetComponent,
    FileDownloadWidgetComponent,
    MtoCommentsContainerComponent,
    MtoCommentComponent,
    MtoCommentDialogComponent,
  ],
  imports: [
    CommonModule,
    LocalizationModule.forRoot(),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient],
        },
    }),
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatDialogModule,
  ],
  exports: [
    // components
    FooterComponent,
    HeaderComponent,
    PageNotFoundComponent,
    LoadingIndicatorComponent,
    AlertPanelComponent,
    InlineValidatonComponent,
    FileWidgetComponent,
    FileDownloadWidgetComponent,
    MtoCommentsContainerComponent,
    MtoCommentComponent,
    MtoCommentDialogComponent,
    // modules
    LocalizationModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatDialogModule,
  ]
})
export class SharedModule { }
