import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StateManagerService } from 'projects/cfs-public/src/app/services/state-manager.service';
import { Errors, TowDriverInformation, TowTruckInformation, checkInvalidAndRemoveFromErrors, getFormValidationErrors, scrollToAlertPanel, scrollToTop } from 'projects/common-lib/src/public-api';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-tow-company-driver-page',
  templateUrl: './tow-company-driver-page.component.html',
  styleUrls: ['./tow-company-driver-page.component.scss'],
})
export class TowCompanyDriverPageComponent implements OnInit, OnDestroy {
  @Input() events!: Observable<number>;
  @Output() formValidityChanged = new EventEmitter<Errors>();

  inputForm!: FormGroup;
  submitPressed = false;
  towDriverInformation: TowDriverInformation = {
    towDriverCertificateNum: '',
    towDriverLicenceNum: '',
    towDriverName: '',
  };

  towCompanyInformation: TowTruckInformation = {
    towCertificateNum: '',
    cvorNum: '',
    towingCompanyName: '',
    nameOnTruck: '',
    plateNum: '',
  };

  listOfErrors: string[] = [];
  private eventsSubscription!: Subscription;

  constructor(private stateManagerService: StateManagerService) { }

  ngOnInit() {
    this.towDriverInformation = this.stateManagerService.getTowDriverInformation();
    this.towCompanyInformation = this.stateManagerService.getTowTruckInformation();
    this.eventsSubscription = this.events.subscribe((stepperStep) => {
      if (stepperStep === 3) {
        this.verify();
      }
      this.saveState();
    });
    this.initForm();
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  initForm() {
    this.inputForm = new FormGroup({
      cvorNumInput: new FormControl(this.towCompanyInformation.cvorNum, [Validators.maxLength(9)]),
      towingCompanyNameInput: new FormControl(this.towCompanyInformation.towingCompanyName, [
        Validators.maxLength(100),
        Validators.pattern('^(?!https?://|www.|[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z0-9]+|http://).*'),
      ]), // No URL allowed
      nameOnTruckInput: new FormControl(this.towCompanyInformation.nameOnTruck, [
        Validators.maxLength(100),
        Validators.pattern('^(?!https?://|www.|[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z0-9]+|http://).*'),
      ]),
      plateNumInput: new FormControl(this.towCompanyInformation.plateNum, [
        Validators.maxLength(20),
        Validators.pattern('^[A-Za-z0-9 -]{2,20}$'),
      ]),
      towDriverCertificateNumInput: new FormControl(this.towDriverInformation.towDriverCertificateNum, [
        Validators.maxLength(9),
      ]),
      towDriverLicenceNumInput: new FormControl(this.towDriverInformation.towDriverLicenceNum, [
        Validators.pattern('^[A-Za-z0-9]{1,20}$'),
      ]),
      towDriverNameInput: new FormControl(this.towDriverInformation.towDriverName, [
        Validators.maxLength(100),
        Validators.pattern('^(?!https?://|www.|[a-zA-Z0-9]+@[a-zA-Z0-9]+.[a-zA-Z0-9]+|http://).*'),
      ]),
    });
  }

  checkInvalid(control: string): void {
    const beforeRemove = this.listOfErrors.length;
    checkInvalidAndRemoveFromErrors(this.inputForm, control, this.listOfErrors);
    if (this.listOfErrors.length < beforeRemove && this.submitPressed) {
      this.formValidityChanged.emit({
        validity: this.inputForm.valid,
        listOfErrors: this.listOfErrors,
        childName: 'driver',
      });
    }
  }

  verify() {
    this.inputForm.markAllAsTouched();
    if (this.inputForm.invalid) {
      getFormValidationErrors(this.inputForm, this.listOfErrors);
      scrollToAlertPanel();
    }
    this.submitPressed = true;
    this.formValidityChanged.emit({
      validity: this.inputForm.valid,
      listOfErrors: this.listOfErrors,
      childName: 'driver',
    });
  }

  saveState() {
    this.towCompanyInformation.cvorNum = this.cvorNumInput.value;
    this.towCompanyInformation.towingCompanyName = this.towingCompanyNameInput.value;
    this.towCompanyInformation.nameOnTruck = this.nameOnTruckInput.value;
    this.towCompanyInformation.plateNum = this.plateNumInput.value;
    this.stateManagerService.updateTowTruckInformation(this.towCompanyInformation);

    this.towDriverInformation.towDriverCertificateNum = this.towDriverCertificateNumInput.value;
    this.towDriverInformation.towDriverLicenceNum = this.towDriverLicenceNumInput.value;
    this.towDriverInformation.towDriverName = this.towDriverNameInput.value;
    this.stateManagerService.updateTowDriverInformation(this.towDriverInformation);
  }

  get cvorNumInput(): any {
    return this.inputForm.get('cvorNumInput');
  }
  get towingCompanyNameInput(): any {
    return this.inputForm.get('towingCompanyNameInput');
  }
  get nameOnTruckInput(): any {
    return this.inputForm.get('nameOnTruckInput');
  }
  get plateNumInput(): any {
    return this.inputForm.get('plateNumInput');
  }
  get towDriverCertificateNumInput(): any {
    return this.inputForm.get('towDriverCertificateNumInput');
  }
  get towDriverLicenceNumInput(): any {
    return this.inputForm.get('towDriverLicenceNumInput');
  }
  get towDriverNameInput(): any {
    return this.inputForm.get('towDriverNameInput');
  }
}
