import { Component, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { StateManagerService } from '../../services/state-manager.service';
import { Router } from '@angular/router';
import { IncidentInfoPage1Component } from '../incident-info-components/incident-info-page1/incident-info-page1.component';
import { IncidentInfoPage2Component } from '../incident-info-components/incident-info-page2/incident-info-page2.component';
import { IncidentInfoPage3Component } from '../incident-info-components/incident-info-page3/incident-info-page3.component';
import { IncidentInfoPage4Component } from '../incident-info-components/incident-info-page4/incident-info-page4.component';
import { environment } from 'projects/cfs-public/src/environments/environment';
import { scrollToTop, PublicRouteEnum, Errors, ErrorsEnum, CommonLibService, scrollToAlertPanel } from 'projects/common-lib/src/public-api';

@Component({
  selector: 'app-incident-info',
  templateUrl: './incident-info.component.html',
  styleUrls: ['./incident-info.component.scss'],
})
export class IncidentInfoComponent {
  eventsSubject: Subject<number> = new Subject<number>();
  stepperStep = 1;
  shouldAlert = false;
  childFormValid = false;
  listOfErrors: string[] = [];
  cancelClicked = false;

  showAPIError = false;
  showUploadSuccess = false;
  showMaxFileWarning = false;
  showAuthError = false;

  commonErrorTitle = 'common.alerts.error.title';
  commonErrorMessage = 'common.alerts.error.sub-title';

  uploadSuccessTitle = 'common.alerts.upload-success';
  maxFiles = 'common.alerts.max-files';

  apiErrorTitle = 'common.alerts.submitComplaintError.title';
  apiErrorMessage = 'common.alerts.submitComplaintError.sub-title';
  apiErrorMessage2 = 'common.alerts.submitComplaintError.sub-title2';

  authErrorTitle = 'common.alerts.error.title';
  authErrorMessage = 'tsoa-cfs-public.alerts.error.Server returned an error';

  @ViewChild('incidentInfo1') incidentInfoPage1Component!: IncidentInfoPage1Component;
  @ViewChild('incidentInfo2') incidentInfoPage2Component!: IncidentInfoPage2Component;
  @ViewChild('incidentInfo3') incidentInfoPage3Component!: IncidentInfoPage3Component;
  @ViewChild('incidentInfo4') incidentInfoPage4Component!: IncidentInfoPage4Component;


  constructor(
    private stateManagerService: StateManagerService,
    private commonLibService: CommonLibService,
    private router: Router,
  ) {
    this.stepperStep = this.stateManagerService.getStepperStep();
  }

  emitEventToChild() {
    this.eventsSubject.next(this.stepperStep);
    if (this.childFormValid && this.stepperStep == 4) {
      scrollToTop();
      return;
    }
    else if (this.childFormValid) {
      this.stateManagerService.setStepperStep(this.stepperStep + 1);
      this.stepperStep = this.stateManagerService.getStepperStep();
      this.childFormValid = false;
    }
    scrollToTop();
  }

  goCancel() {
    scrollToAlertPanel();
    this.cancelClicked = true;
  }

  cancel(input: boolean) {
    if (input === true) {
      // delete all entered data
      this.stateManagerService.clearStateObject();
      // then go to home page

      this.stateManagerService.setRouteStep(PublicRouteEnum.LANDING_PAGE);

      this.router.navigate([this.commonLibService.getCurrentLanguage() + `/${environment.rootRoute}/`]);
    } else {
      // else hide the alert
      this.cancelClicked = false;
    }
  }

  emitEventToChildCancel() {
    this.childFormValid = false;
    this.stateManagerService.setStepperStep(1);
    this.stepperStep = this.stateManagerService.getStepperStep();
    this.eventsSubject.next(0);
  }

  backPressed() {
    this.childFormValid = true;
    this.shouldAlert = false;
    this.showUploadSuccess = false;
    this.stateManagerService.setStepperStep(this.stepperStep - 1);
    this.stepperStep = this.stateManagerService.getStepperStep();
    this.stateManagerService.setRouteStep(PublicRouteEnum.CONTACT_INFO);
    this.eventsSubject.next(0);
  }

  onFormValidityChanged(errors: Errors) {
    // check if API error
    if (errors.listOfErrors.includes(ErrorsEnum.API_ERROR)) {
      this.showAPIError = true;
      this.shouldAlert = false;
      errors.listOfErrors.pop();
    } else if (errors.listOfErrors.includes(ErrorsEnum.AUTH_ERROR)) {
      this.showAuthError = true;
      this.shouldAlert = false;
      errors.listOfErrors.pop();
    } else {
      this.showAPIError = false;
      this.showAuthError = false;
      this.shouldAlert = !errors.validity;
    }

    this.childFormValid = errors.validity;
    this.listOfErrors = errors.listOfErrors;
  }

  displayUploadSuccess(IShould: boolean) {
    this.showUploadSuccess = IShould;
  }

  displayMaxFileWarning(IShould: boolean) {
    this.showMaxFileWarning = IShould;
    if (IShould)
      scrollToTop();
  }

  scrollToValidation(error: string) {
    if (this.stepperStep === 1) {
      this.incidentInfoPage1Component.scrollToValidation(error);
    }
    else if (this.stepperStep === 2) {
      this.incidentInfoPage2Component.scrollToValidation(error);
    }
    else if (this.stepperStep === 3) {
      this.incidentInfoPage3Component.scrollToValidation(error);
    }
    else if (this.stepperStep === 4) {
      this.incidentInfoPage4Component.scrollToValidation(error);
    }
  }

}

