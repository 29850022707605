<div class='ontario-row'>
    <div class='ontario-columns ontario-small-12 ontario-medium-12 ontario-large-12'>
        <h2 class="ontario-h1">{{ 'tsoa-cfs-public.fourthFormsPage.title' | translate }}</h2>
        <form [formGroup]="inputForm">
            <h2>{{ 'tsoa-cfs-public.fourthFormsPage.Summary of incident' | translate }}</h2>
            <div class="ontario-form-group">
                <label class="ontario-label" for="summaryOfIncidentInput">
                    {{ 'tsoa-cfs-public.fourthFormsPage.What happened' | translate }}<span
                        class="ontario-label__flag">({{ 'common.required' | translate }})</span>
                </label>
                <p>{{ 'tsoa-cfs-public.fourthFormsPage.Include information' | translate }}</p>
                <lib-inline-validaton *ngIf="summaryOfIncidentInput?.touched && summaryOfIncidentInput?.invalid"
                    [fieldName]="'summaryOfIncidentInput'"
                    [message]="'tsoa-cfs-public.fourthFormsPage.Summary required'"
                    id="validation.summaryOfIncidentInput.required"></lib-inline-validaton>
                <textarea class="ontario-input ontario-textarea" maxlength="5000" name="text-area-example"
                    id="summaryOfIncidentInput" formControlName="summaryOfIncidentInput"
                    placeholder="{{ 'tsoa-cfs-public.fourthFormsPage.Enter details here' | translate }}"
                    [ngClass]="{'ontario-input__error': summaryOfIncidentInput?.touched && summaryOfIncidentInput?.invalid }"
                    (change)="checkInvalid('summaryOfIncidentInput')">
                </textarea>
                <div class="maxCharacters">
                    <span>{{ 'tsoa-cfs-public.fourthFormsPage.Max characters' | translate }}</span>
                    <span style="float: right;">{{ this.summaryOfIncidentInput?.value.length }}/5000</span>
                </div>
            </div>

            <h2>{{ 'tsoa-cfs-public.fourthFormsPage.Upload documents' | translate }}</h2>
            <p>{{ 'tsoa-cfs-public.fourthFormsPage.Upload documents here' | translate }}</p>

            <div id='accordion-1'>
                <span class='ontario-accordion-heading'>
                    <button class='ontario-accordion__button' id='accordion-button-id-1'
                        aria-controls='accordion-content-1' attr.aria-expanded='{{!isCollapsed}}'
                        data-toggle='ontario-collapse' (click)="toggle()">
                        <span ngClass='spanClass' id="open">
                            <svg class="ontario-icon" alt="" aria-hidden="false" focusable="false"
                                sol:category="primary" viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                                <use [attr.href]="chevron"></use>
                            </svg>
                        </span>
                        {{ 'tsoa-cfs-public.fourthFormsPage.Document upload types' | translate }}
                    </button>
                </span>
                <section class='ontario-accordion__content' id='accordion-content-1'
                    aria-labelledby='accordion-button-id-1' attr.aria-hidden='{{isCollapsed}}'
                    data-toggle='ontario-expander-content' [ngStyle]="{ 'display': display }">
                    <div class="ontario-callout">
                        <p>{{ 'tsoa-cfs-public.fourthFormsPage.Documents allowed' | translate }}<br>
                            {{ 'tsoa-cfs-public.fourthFormsPage.Document formats' | translate }}</p>

                        <p>{{ 'tsoa-cfs-public.fourthFormsPage.Video allowed' | translate }}<br>
                            {{ 'tsoa-cfs-public.fourthFormsPage.Video formats' | translate }}</p>

                        <p>{{'tsoa-cfs-public.fourthFormsPage.Documents Size' | translate}}</p>
                    </div>
                </section>
            </div>
            <lib-inline-validaton *ngIf="isSizeError()" [fieldName]="'file-upload'"
                [message]="'tsoa-cfs-public.alerts.error.File size too large'"></lib-inline-validaton>
            <div class="flex-wrapper">
                <p style="visibility: hidden; width: 0;">
                    {{ 'tsoa-cfs-public.fourthFormsPage.Upload' | translate }}
                </p>
                <label tabindex="0" (keypress)="file.click()"
                    class="ontario-button ontario-button--secondary upload-button" for="file-upload">{{
                    'tsoa-cfs-public.fourthFormsPage.Upload' | translate }}</label>
                <input #file type="file" id="file-upload" name="file-upload" hidden (change)="filesUpload($event)"
                    multiple>
                <div class="flex-break"></div>
                <div id="listOfUploaded" tabindex="0" *ngFor="let fileWidget of incidentSummary.documents"
                    class="file-container">
                    <lib-file-widget [fileWidget]="fileWidget"
                        (thisOneDad)="deleteFromUploaded($event)"></lib-file-widget>
                </div>
            </div>

            <p><br></p>
            <label for="referenceNumberInput">
                <h3>{{ 'tsoa-cfs-public.fourthFormsPage.Add reference number' | translate }}</h3>
            </label>
            <p>{{ 'tsoa-cfs-public.fourthFormsPage.Wish to include reference numbers' | translate }}</p>
            <input class="ontario-input" type="text" id="referenceNumberInput" maxlength="100"
                formControlName="referenceNumberInput" />
        </form>
    </div>

    <lib-loading-indicator *ngIf="showLoadingIndicator" [fullScreen]="true"></lib-loading-indicator>

</div>