import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UploadAttachementResult } from 'projects/common-lib/src/public-api';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AttachementService {
  private apiUrl = `${environment.APIEndpoint}/attachment/upload`;
  constructor(private httpClient: HttpClient) { }

  postAttachement(newAttachement: File, sessionUUID: string, documentUUID: string): Observable<HttpEvent<UploadAttachementResult>> {

    const fd = new FormData();
    let headers;

    fd.append('sessionUUID', sessionUUID);
    fd.append('documentUUID', documentUUID);
    fd.append('file', newAttachement);

    // Content-Type is being set by browser so that it fills
    // in boundary between form types
    return this.httpClient.post<UploadAttachementResult>(this.apiUrl, fd, { headers: headers, observe: 'events', reportProgress: true });
  }

  getAttachment(documentName: string): Observable<Blob> {
    return this.httpClient.get(`${environment.APIEndpoint}/attachment/${documentName}`, { responseType: 'blob' });
  }

}
