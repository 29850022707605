<app-stepper [step]="stepperStep" (backPressed)="backPressed()"></app-stepper>

<lib-alert-panel id="alertPanel" (scrollToValidation)="scrollToValidation($event)" *ngIf="cancelClicked"
    (cancelEvent)="cancel($event)" [alertType]="'cancel'" [alertTitle]="'common.alerts.cancel.title'"
    [alertMessage]="['common.alerts.cancel.sub-title']"></lib-alert-panel>

<lib-alert-panel id="alertPanel" (scrollToValidation)="scrollToValidation($event)" *ngIf="showAPIError"
    [alertType]="'apiError'" [alertTitle]="apiErrorTitle"
    [alertMessage]="[apiErrorMessage, apiErrorMessage2]"></lib-alert-panel>

<lib-alert-panel id="alertPanel" (scrollToValidation)="scrollToValidation($event)" *ngIf="showAuthError"
    [alertType]="'authError'" [alertTitle]="authErrorTitle" [alertMessage]="[authErrorMessage]"></lib-alert-panel>

<lib-alert-panel id="alertPanel" (scrollToValidation)="scrollToValidation($event)"
    *ngIf="shouldAlert && listOfErrors.length > 0" [alertType]="'error'" [alertTitle]="commonErrorTitle"
    [alertMessage]="[commonErrorMessage]" [alertErrorList]="listOfErrors"></lib-alert-panel>

<lib-alert-panel id="successPanel" (scrollToValidation)="scrollToValidation($event)"
    *ngIf="showUploadSuccess  && stepperStep===4" [alertType]="'success'"
    [alertTitle]="uploadSuccessTitle"></lib-alert-panel>

<lib-alert-panel id="alertPanel" (scrollToValidation)="scrollToValidation($event)"
    *ngIf="showMaxFileWarning && stepperStep===4" [alertType]="'warning'" [alertTitle]="maxFiles"></lib-alert-panel>

<app-incident-info-page1 *ngIf="stepperStep===1" [events]="eventsSubject.asObservable()"
    (formValidityChanged)="onFormValidityChanged($event)" #incidentInfo1></app-incident-info-page1>
<app-incident-info-page2 *ngIf="stepperStep===2" [events]="eventsSubject.asObservable()"
    (formValidityChanged)="onFormValidityChanged($event)" #incidentInfo2></app-incident-info-page2>
<app-incident-info-page3 *ngIf="stepperStep===3" [eventsSubject]="eventsSubject"
    (formValidityChanged)="onFormValidityChanged($event)" #incidentInfo3></app-incident-info-page3>
<app-incident-info-page4 *ngIf="stepperStep===4" [events]="eventsSubject.asObservable()"
    (formValidityChanged)="onFormValidityChanged($event)" (uploadBatchSuccess)="displayUploadSuccess($event)"
    (maxFileWarning)="displayMaxFileWarning($event)" #incidentInfo4></app-incident-info-page4>

<div class='ontario-row'>
    <div class='ontario-columns ontario-small-12 ontario-medium-12 ontario-large-8 button-cluster'>

        <button (click)="emitEventToChild()" *ngIf="stepperStep!==4;else elseBlock"
            class="ontario-button ontario-button--primary" type="primary">{{"common.next" | translate}}</button>
        <ng-template #elseBlock>
            <button (click)="emitEventToChild()" class="ontario-button ontario-button--primary" type="primary">
                {{"common.submit" | translate}}</button>
        </ng-template>
        <button (click)="goCancel()" class="ontario-button ontario-button--secondary" type="secondary">
            {{"common.cancel" | translate}}</button>
    </div>
</div>