import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonLibService {

  private currentLanguage = '';

  constructor() { }

  setCurrentLanguage(value: string) {
    this.currentLanguage = value;
  }

  getCurrentLanguage(): string {
    return this.currentLanguage;
  }
}
