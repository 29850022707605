import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MtoCommentDialogComponent } from '../mto-comment-dialog/mto-comment-dialog.component';
import { Comment } from 'projects/common-lib/src/public-api';

@Component({
  selector: 'lib-mto-comment',
  templateUrl: './mto-comment.component.html',
  styleUrls: ['./mto-comment.component.scss']
})
export class MtoCommentComponent {

  @Input() comment!: Comment;

  constructor(private dialog: MatDialog) { }

  openCommentDialog() {
    const dialogRef = this.dialog.open(MtoCommentDialogComponent, {
      data: {
        mtoComment: this.comment,
      }
    });
  }

}
