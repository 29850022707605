import { Injectable } from '@angular/core';
// import { Apollo } from 'apollo-angular';
// import { CREATE_TSOA_COMPLAINT, GET_ALL_TSOA_COMPLAINTS } from '../common-repositories/graphql.queries';
import { Observable, map } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TsoaComplaintGetOneResult, TsoaComplaint, TsoaAuditResult, DownloadPDFResponse, TsoaComplaintListResult, TsoaComplaintResult } from 'projects/common-lib/src/public-api';

@Injectable({
  providedIn: 'root',
})
export class ComplaintService {

  private headers = new HttpHeaders({
    "Content-Type": "application/json",
    "Accept": "application/json"
  });

  constructor(private http: HttpClient) { }

  // -------------- REST functions --------------

  submitComplaintWithREST(complaint: TsoaComplaint): Observable<TsoaComplaintGetOneResult> {
    const url = `${environment.APIEndpoint}/tsoa/create`;

    return this.http.post<TsoaComplaintGetOneResult>(url, complaint, { headers: this.headers });
  }

  // ------- reCaptcha -------

  verifyReCaptcha(token: string): Observable<any> {
    const url = `${environment.APIEndpoint}/verify-recaptcha`;
    return this.http.post(
      url,
      {
        token,
      },
      {
        headers: this.headers,
      });
  }

  // ------- extend session -------
  extendUserSession(sessionUUID: string): Observable<any> {
    const url = `${environment.APIEndpoint}/cfs/session/${sessionUUID}`;
    return this.http.put(
      url,
      {
        headers: this.headers,
      });
  }


  downloadPDF(sessionUUID: string, referenceNum: string, language: string, submitDate: string): Observable<DownloadPDFResponse> {
    const url = `${environment.APIEndpoint}/tsoa/pdf`;

    return this.http.post<DownloadPDFResponse>(
      url,
      {
        sessionUUID,
        referenceNum,
        language,
        submitDate
      },
      {
        headers: this.headers,
      });
  }

  // -------------- GQL functions --------------

  // getAllComplaintsWithGQL(): Observable<TsoaComplaintListResult> {
  //   return this.apollo
  //     .watchQuery<TsoaComplaintListResult>({
  //       query: GET_ALL_TSOA_COMPLAINTS,
  //       fetchPolicy: 'cache-and-network', // adding this to by-pass only apollo caching, forces to do a network request every time this is called.
  //     })
  //     .valueChanges.pipe(map((result) => result.data));
  // }

  // submitComplaintWithGQL(complaint: TsoaComplaint): Observable<TsoaComplaintResult | null | undefined> {
  //   return this.apollo
  //     .mutate<TsoaComplaintResult | null | undefined>({
  //       mutation: CREATE_TSOA_COMPLAINT,
  //       variables: {
  //         input: complaint,
  //       },
  //     })
  //     .pipe(map((result) => result.data));
  // }
}
