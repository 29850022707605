<div class="ontario-row">
    <div class='ontario-columns ontario-small-12 ontario-medium-12 ontario-large-12'>

        <h2 class="ontario-h1">{{ 'tsoa-cfs-public.thirdFormsPage.title' | translate }}</h2>
        <p>{{ 'tsoa-cfs-public.thirdFormsPage.info' | translate }}</p>

        <div class="non-ordered-layout" *ngIf="!renderVehicleStorageFirst">
            <app-tow-company-driver-page [events]="eventsSubject.asObservable()"
                (formValidityChanged)="onFormValidityChanged($event)"></app-tow-company-driver-page>
        </div>
        <div class="tow-company-first" *ngIf="renderVehicleStorageFirst">
            <app-vehicle-storage-page [events]="eventsSubject.asObservable()"
                (formValidityChanged)="onFormValidityChanged($event)"></app-vehicle-storage-page>
        </div>

        <div *ngIf="renderAddInfo" class="ontario-form-group">
            <fieldset>
                <legend class="ontario-fieldset__legend">
                    <div *ngIf="renderVehicleStorageFirst">
                        {{ 'tsoa-cfs-public.thirdFormsPage.Add tow info' | translate }}<span
                            class="ontario-label__flag">({{'common.required' | translate }})</span>
                    </div>
                    <div *ngIf="!renderVehicleStorageFirst">
                        {{ 'tsoa-cfs-public.thirdFormsPage.Add storage info' | translate }}<span
                            class="ontario-label__flag">({{'common.required' | translate }})</span>
                    </div>
                </legend>
                <lib-inline-validaton *ngIf="!this.pageErrors.validity" [fieldName]="'addInfo'"
                    [message]="'common.required'" id="validation.add info required"></lib-inline-validaton>

                <div *ngIf="alreadySelectedTow;else elseBlock">
                    <div class="ontario-radios">
                        <div class="ontario-radios__item">
                            <input class="ontario-radios__input" id="add info required" name="radio-buttons"
                                type="radio" value="yes"
                                [checked]="shouldShowStorageInfo !== null ? shouldShowStorageInfo:false"
                                (change)="addInfo()">
                            <label [ngClass]="{'ontario-input__error': !this.pageErrors.validity }"
                                class="ontario-label ontario-radios__label" for="add info required">
                                {{'common.yes' | translate }}
                            </label>
                        </div>
                        <div class="ontario-radios__item">
                            <input class="ontario-radios__input" id="no-add" name="radio-buttons" type="radio"
                                value="no" [checked]="shouldShowStorageInfo !== null ? !shouldShowStorageInfo : false"
                                (change)="clickNo()">
                            <label [ngClass]="{'ontario-input__error': !this.pageErrors.validity }"
                                class="ontario-label ontario-radios__label" for="no-add">
                                {{'common.no' | translate }}
                            </label>
                        </div>
                    </div>
                </div>
                <ng-template #elseBlock>
                    <div class="ontario-radios">
                        <div class="ontario-radios__item">
                            <input class="ontario-radios__input" id="add info required" name="radio-buttons"
                                type="radio" value="yes"
                                [checked]="shouldShowVehicleTowingOrTowDriverInfo !== null ? shouldShowVehicleTowingOrTowDriverInfo : false"
                                (change)="addInfo()">
                            <label [ngClass]="{'ontario-input__error': !this.pageErrors.validity }"
                                class="ontario-label ontario-radios__label" for="add info required">
                                {{'common.yes' | translate }}
                            </label>
                        </div>
                        <div class="ontario-radios__item">
                            <input class="ontario-radios__input" id="no-add" name="radio-buttons" type="radio"
                                value="no"
                                [checked]="shouldShowVehicleTowingOrTowDriverInfo !== null ? !shouldShowVehicleTowingOrTowDriverInfo : false"
                                (change)="clickNo()">
                            <label [ngClass]="{'ontario-input__error': !this.pageErrors.validity }"
                                class="ontario-label ontario-radios__label" for="no-add">
                                {{'common.no' | translate }}
                            </label>
                        </div>
                    </div>
                </ng-template>
            </fieldset>

        </div>

        <div class="non-ordered-layout" *ngIf="!renderVehicleStorageFirst">
            <app-vehicle-storage-page *ngIf="this.shouldShowStorageInfo" [events]="eventsSubject.asObservable()"
                (formValidityChanged)="onFormValidityChanged($event)"></app-vehicle-storage-page>
        </div>

        <div class="tow-company-first" *ngIf="renderVehicleStorageFirst">
            <app-tow-company-driver-page *ngIf="this.shouldShowVehicleTowingOrTowDriverInfo"
                [events]="eventsSubject.asObservable()"
                (formValidityChanged)="onFormValidityChanged($event)"></app-tow-company-driver-page>
        </div>
    </div>
</div>