import { NgModule } from '@angular/core';
import { CommonLibComponent } from './common-lib.component';

/* Modules */
import { SharedModule } from './modules/shared.module';

@NgModule({
  declarations: [
    CommonLibComponent,
  ],
  imports: [
  ],
  exports: [
    // must export the common lib component/module and any components used
    CommonLibComponent,
    SharedModule,
  ]
})
export class CommonLibModule { }
