<div class="ontario-row">
    <div class='ontario-columns ontario-small-12 ontario-medium-12 ontario-large-12'>
        <div class="storageInfo">
            <h2>{{ 'tsoa-cfs-public.thirdFormsPage.Vehicle storage info' | translate }}</h2>

            <form [formGroup]="inputForm">
                <label for="storageCertificateNumInput">
                    <h3>{{ 'tsoa-cfs-public.thirdFormsPage.Storage certificate num' | translate }}</h3>
                </label>
                <p class="ontario-hint" id="">{{ 'tsoa-cfs-public.thirdFormsPage.Storage certificate num example' |
                    translate }}</p>
                <div class="input-group">
                    <div class="ontario-input ontario-input--2-char-width">
                        VS</div>
                    <input class="ontario-input ontario-input--20-char-width" type="text"
                        id="storageCertificateNumInput" formControlName="storageCertificateNumInput" mask="000-000-000"
                        [validation]="false" />
                </div>

                <label for="vehicleStorageCompanyNameInput">
                    <h3>{{ 'tsoa-cfs-public.thirdFormsPage.Vehicle storage company name' | translate }}</h3>
                </label>
                <lib-inline-validaton
                    *ngIf="vehicleStorageCompanyNameInput.touched && vehicleStorageCompanyNameInput.invalid"
                    [fieldName]="'vehicleStorageCompanyNameInput'"
                    [message]="'tsoa-cfs-public.alerts.error.vehicleStorageCompanyNameInput.pattern'"
                    id="validation.error.vehicleStorageCompanyNameInput.pattern"></lib-inline-validaton>
                <input class="ontario-input" type="text" id="vehicleStorageCompanyNameInput"
                    formControlName="vehicleStorageCompanyNameInput"
                    (change)="checkInvalid('vehicleStorageCompanyNameInput')"
                    [ngClass]="{'ontario-input__error': vehicleStorageCompanyNameInput.touched && vehicleStorageCompanyNameInput.invalid }"
                    maxlength="200" />

                <label for="storageLocationNumInput">
                    <h3>{{ 'tsoa-cfs-public.thirdFormsPage.Storage location num' | translate }}</h3>
                </label>
                <p class="ontario-hint" id="">{{ 'tsoa-cfs-public.thirdFormsPage.Storage location example' | translate
                    }}</p>
                <lib-inline-validaton *ngIf="storageLocationNumInput.touched && storageLocationNumInput.invalid"
                    [fieldName]="'storageLocationNumInput'"
                    [message]="'tsoa-cfs-public.alerts.error.storageLocationNumInput.minlength'"
                    id="validation.error.storageLocationNumInput.minlength"></lib-inline-validaton>
                <input class="ontario-input" type="text" id="storageLocationNumInput"
                    formControlName="storageLocationNumInput" mask="9{10}" (change)="pad('storageLocationNumInput')"
                    (change)="checkInvalid('storageLocationNumInput')"
                    [ngClass]="{'ontario-input__error': storageLocationNumInput.touched && storageLocationNumInput.invalid }" />

                <label for="addressOfStoredVehicleInput">
                    <h3>{{ 'tsoa-cfs-public.thirdFormsPage.Address of vehicle' | translate }}</h3>
                </label>
                <lib-inline-validaton *ngIf="addressOfStoredVehicleInput.touched && addressOfStoredVehicleInput.invalid"
                    [fieldName]="'addressOfStoredVehicleInput'"
                    [message]="'tsoa-cfs-public.alerts.error.addressOfStoredVehicleInput.pattern'"
                    id="validation.error.addressOfStoredVehicleInput.pattern"></lib-inline-validaton>
                <input class="ontario-input" type="text" id="addressOfStoredVehicleInput"
                    formControlName="addressOfStoredVehicleInput" maxlength="200"
                    (change)="checkInvalid('addressOfStoredVehicleInput')"
                    [ngClass]="{'ontario-input__error': addressOfStoredVehicleInput.touched && addressOfStoredVehicleInput.invalid }" />
            </form>
        </div>
    </div>
</div>