<div class="ontario-step-indicator">
    <div class="ontario-row">
        <div class="ontario-columns ontario-small-12">
            <div class="ontario-step-indicator--with-back-button">
                <button *ngIf="step !== 1" (click)="backPress()" class="ontario-button ontario-button--tertiary">
                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary"
                        viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-chevron-left"></use>
                    </svg>{{'common.back' | translate}}
                </button>

                <button *ngIf="step === 1" (click)="backPress()" routerLink="../contact-info"
                    class="ontario-button ontario-button--tertiary">
                    <svg class="ontario-icon" alt="" aria-hidden="true" focusable="false" sol:category="primary"
                        viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
                        <use href="#ontario-icon-chevron-left"></use>
                    </svg>{{'common.back' | translate}}
                </button>
                <span class="ontario-h4">Step&nbsp;{{step}} of&nbsp;4</span>
            </div>
            <hr />
        </div>
    </div>
</div>