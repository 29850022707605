export const environment = {
    production: false,
    rootRoute: 'tssea',
    APIEndpoint: 'https://api.dev.clientfeedback.mto.gov.on.ca',
    disableRecaptcha: true,
    recaptchaSiteKey: '6Ldj_TIpAAAAAAEzbDLXExfB5p6L8ScUKvPIDn0V',
    disableRouteGuard: false, // make true if you want to prevent users changing the url to skip through the pages on the website
    brokerURL: 'wss://api.dev.clientfeedback.mto.gov.on.ca/document-status',
    sessionWarningConfig: {
        idle: 600, // in seconds, how long the user has to be idle for before receiving a session expiry warning
        timeout: 60, // in seconds, how long the user has to either extend the session or end the session
        ping: 5 // in seconds, a value that can be used to 'ping' the user, we don't use it but still needs to be defined here
    },
    disableSessionWarning: false,
    disableSessionWarningForAdmin: true, // regulate when to use admin session warning logic
    googleAnalyticsID: 'G-K0WK1R2NV4',
    // hmacSecurity: true,
    // hmacAlgorithm: 'sha256',
    // hmacSecret: '__HMAC_SECRET__',
};
