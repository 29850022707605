<div *ngIf="fullScreen" class='ontario-loading-indicator__overlay' aria-hidden="false" role='alert'>
    <div class='ontario-loading-indicator'>
        <svg class='ontario-loading-indicator__spinner' viewBox="25 25 50 50" xmlns='http://www.w3.org/2000/svg'>
            <circle cx="50" cy="50" [attr.r]="spinnerRadius" fill="none" stroke-width="4" />
        </svg>
        <p>Loading</p>
    </div>

</div>
<div *ngIf="!fullScreen" class="small-spin" aria-hidden="false" role='alert'>
    <svg class='ontario-loading-indicator__spinner' viewBox="25 25 50 50" xmlns='http://www.w3.org/2000/svg'>
        <circle cx="50" cy="50" [attr.r]="spinnerRadius" fill="none" stroke-width="4" />
    </svg>
    <p>Loading</p>
</div>