import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import incidentPage1Options from "projects/common-lib/src/lib/assets/jsonData/incidentPage1Options.json";
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Errors, ComplainAbout, requireCheckboxesToBeCheckedValidator, requireCheckboxesToBeCheckedArray, scrollToValidation, checkInvalidAndRemoveFromErrors, getFormValidationErrors, scrollToTop, scrollToAlertPanel } from 'projects/common-lib/src/public-api';
import { StateManagerService } from '../../../services/state-manager.service';

@Component({
  selector: 'app-incident-info-page1',
  templateUrl: './incident-info-page1.component.html',
  styleUrls: ['./incident-info-page1.component.scss'],
})
export class IncidentInfoPage1Component implements OnInit, OnDestroy {
  @Output() formValidityChanged = new EventEmitter<Errors>();

  reasonForInteractionOptions = incidentPage1Options.reasonForInteractionOptions;
  typeOfComplaintOptions = incidentPage1Options.typeOfComplaintOptions;
  complaintSubjectOptions = incidentPage1Options.complaintSubjectOptions;

  complainAbout: ComplainAbout = {
    complaintSubject: [],
    typeOfInteraction: '',
    typeOfComplaint: [],
  };

  constructor(
    private stateManagerService: StateManagerService,
    private titleService: Title,
    private router: Router
  ) {
    if (this.router.url.includes('fr/tssea')) {
      this.titleService.setTitle('Type de plainte | BSREV'); // French tab title
    }
    else {
      this.titleService.setTitle('Complaint Type | TVSO'); // English tab title
    }
  }

  externalForm!: FormGroup;
  listOfErrors: string[] = [];

  private eventsSubscription!: Subscription;

  @Input() events!: Observable<number>;

  ngOnInit(): void {
    this.eventsSubscription = this.events.subscribe((stepperStep) => {
      if (stepperStep === 1) {
        this.verifyValidity();
      }
      this.saveState();
    });

    this.complainAbout = this.stateManagerService.getComplainAbout();

    this.initForm();
  }

  initForm(): void {
    this.externalForm = new FormGroup({
      inputForm: new FormGroup(
        {
          towDriverInput: new FormControl(
            this.complainAbout.complaintSubject.includes(this.complaintSubjectOptions['Tow Driver']),
          ),
          vehicleTowingCompanyInput: new FormControl(
            this.complainAbout.complaintSubject.includes(this.complaintSubjectOptions['Vehicle Towing Company']),
          ),
          vehicleStorageCompanyInput: new FormControl(
            this.complainAbout.complaintSubject.includes(this.complaintSubjectOptions['Vehicle Storage Company']),
          ),
        },
        [requireCheckboxesToBeCheckedValidator()],
      ),
      interactionType: new FormControl(this.complainAbout.typeOfInteraction, [Validators.required]),
      complaintOptions: new FormArray([], [requireCheckboxesToBeCheckedArray()]),
    });

    this.typeOfComplaintOptions.forEach((option) =>
      this.complaintOptions.controls.push(
        new FormControl(this.complainAbout.typeOfComplaint.includes(option), { updateOn: 'change' }),
      ),
    );
  }

  get interactionType(): any {
    return this.externalForm.get('interactionType');
  }

  get towDriverInput(): any {
    return this.inputForm.get('towDriverInput');
  }

  get vehicleTowingCompanyInput(): any {
    return this.inputForm.get('vehicleTowingCompanyInput');
  }

  get vehicleStorageCompanyInput(): any {
    return this.inputForm.get('vehicleStorageCompanyInput');
  }

  get complaintOptions() {
    return this.externalForm.get('complaintOptions') as FormArray;
  }

  get inputForm() {
    return this.externalForm.get('inputForm') as FormGroup;
  }

  atLeastOneCheckboxTouched(): boolean {
    return this.complaintOptions.controls.some((control) => control.touched);
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  updateTypeofComplaintMultiSelect(e: EventTarget | null) {
    if (e === null) return;

    const value = (e as HTMLInputElement).value;
    if (this.complainAbout.typeOfComplaint.includes(value)) {
      this.complainAbout.typeOfComplaint = this.complainAbout.typeOfComplaint.filter((type) => type != value);
    } else this.complainAbout.typeOfComplaint.push(value);
    this.complaintOptions.updateValueAndValidity();
    this.checkInvalid('complaintOptions');
  }

  updateSelectedReason(e: EventTarget | null) {
    if (e === null) return;
    this.complainAbout.typeOfInteraction = (e as HTMLInputElement).value;
    this.checkInvalid('interactionType');
  }

  multiSelectChange(e: EventTarget | null) {
    if (e === null) return;
    const value = (e as HTMLInputElement).defaultValue;
    if (this.complainAbout.complaintSubject.includes(value)) {
      this.complainAbout.complaintSubject = this.complainAbout.complaintSubject.filter((subject) => subject != value);
    } else this.complainAbout.complaintSubject.push(value);
    this.checkInvalid('inputForm');
  }

  checkInvalid(control: string): void {
    checkInvalidAndRemoveFromErrors(this.externalForm, control, this.listOfErrors);
  }

  verifyValidity() {
    this.externalForm.markAllAsTouched();
    if (this.externalForm.invalid) {
      getFormValidationErrors(this.externalForm, this.listOfErrors);
      scrollToAlertPanel();
    }
    this.formValidityChanged.emit({ validity: this.externalForm.valid, listOfErrors: this.listOfErrors });
  }

  saveState() {
    this.stateManagerService.updateComplainAbout(this.complainAbout);
  }

  scrollToValidation = (error: string): void => {
    var id = error.split('.')[0];
    if (id == 'inputForm') {
      id = "towDriver";
    }
    else if (id == 'complaintOptions') {
      id = "DRV_BEHAVIOR";
    }

    const elementToScrollTo = document.getElementById(id);
    if (elementToScrollTo) {
      elementToScrollTo.focus();
    }
  };
}
