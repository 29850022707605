<div class="mto-comment">
    <div class='ontario-row' style="margin-top: 0; margin-bottom: 0; padding-top: 0; padding-bottom: 0;">

        <div class="ontario-columns ontario-large-3 ontario-medium-3 ontario-small-12">
            {{ comment.createDate | date
            : "MMMM dd, YYYY h:mm a" }}
        </div>

        <div class="ontario-columns ontario-large-2 ontario-medium-2 ontario-small-12 username">
            {{ comment.userName}}
        </div>

        <div class="ontario-columns ontario-large-7 ontario-medium-7 ontario-small-12">
            <span *ngIf="comment.comment.length > 150">
                {{ comment.comment.substring(0, 150) }}...
                <a tabindex="0" (keypress)="openCommentDialog()" class="more-button ontario-button--tertiary"
                    (click)="openCommentDialog()">more</a>
            </span>
            <span *ngIf="comment.comment.length <= 150">
                {{ comment.comment }}
            </span>
        </div>
    </div>
    <hr class="ontario-hr--dark">
</div>